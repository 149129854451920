import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type HistoryASDataObject } from '../types'

export interface HistoryASTableState {
  isLoading: boolean
  errorMessage: string | undefined
  data: HistoryASDataObject | undefined
  httpStatus: number
}

const initialState: HistoryASTableState = {
  isLoading: true,
  errorMessage: undefined,
  data: undefined,
  httpStatus: 0
}

const historyASTableSlice = createSlice({
  name: 'KpiHistoryASTable',
  initialState,
  reducers: {
    historyASTableLoadingAction: (state) => {
      return {
        isLoading: true,
        errorMessage: undefined,
        data: undefined,
        httpStatus: 0
      }
    },
    historyASTableUpdateAction: (state, action: PayloadAction<{ data: HistoryASDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: undefined,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    historyASTableErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        data: undefined,
        httpStatus: action.payload.httpStatus
      }
    },
    historyASTableResetAction: () => {
      return initialState
    }
  }
})

export const {
  historyASTableLoadingAction,
  historyASTableUpdateAction,
  historyASTableErrorAction,
  historyASTableResetAction
} = historyASTableSlice.actions

export default historyASTableSlice.reducer
