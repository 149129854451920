import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type HistoryADSDataObject } from '../types'

export interface HistoryADSTableState {
  isLoading: boolean
  errorMessage: string | undefined
  data: HistoryADSDataObject | undefined
  httpStatus: number
}

const initialState: HistoryADSTableState = {
  isLoading: true,
  errorMessage: undefined,
  data: undefined,
  httpStatus: 0
}

const historyADSTableSlice = createSlice({
  name: 'KpiHistoryADSTable',
  initialState,
  reducers: {
    historyADSTableLoadingAction: (state) => {
      return {
        isLoading: true,
        errorMessage: undefined,
        data: undefined,
        httpStatus: 0
      }
    },
    historyADSTableUpdateAction: (state, action: PayloadAction<{ data: HistoryADSDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: undefined,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    historyADSTableErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        data: undefined,
        httpStatus: action.payload.httpStatus
      }
    },
    historyADSTableResetAction: () => {
      return initialState
    }
  }
})

export const {
  historyADSTableLoadingAction,
  historyADSTableUpdateAction,
  historyADSTableErrorAction,
  historyADSTableResetAction
} = historyADSTableSlice.actions

export default historyADSTableSlice.reducer
