/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { type Options, type PointOptionsObject, type SeriesLineOptions, type SeriesAreasplineOptions } from 'highcharts'
import { convertDateIntoAohStandardUsingDayjs } from '../../../../customHooks/parseDate'
import { type NETWORK_KPI, networkDetailTooltip, getTooltipText } from '../../../../utils/NetworkGraphUtils'
import { getDataSourceTooltip } from '../../../../utils/DataSourceUtils'
import { type ChannelDropdownData } from '../../services/channelDropDownService'

export function getAssetDataOptions (
  historyData: Array<(number | [(number | string), (number | null)] | null | PointOptionsObject)> = [[null, null]]
): Options {
  const seriesOptions: SeriesAreasplineOptions[] = [
    {
      type: 'areaspline',
      name: 'Asset Health',
      data: historyData,
      marker: { enabled: false },
      lineColor: '#97E7CC99',
      lineWidth: 10
    }
  ]

  return ({
    chart: {
      type: 'areaspline',
      style: {
        fontSize: '16px',
        fontFamily: 'Honeywell Sans Web'
      }
    },
    title: {
      text: ''
    },
    series: seriesOptions,
    colors: [
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, '#97E7CC'],
          [1, '#C4C4C400']
        ]
      }
    ],
    legend: {
      itemStyle: {
        fontWeight: '400',
        fontFamily: 'Honeywell Sans Web',
        fontSize: '16px'
      }
    },
    xAxis: {
      title: {
        text: 'Date'
      },
      type: 'datetime'
    },
    yAxis: {
      title: {
        text: '',
        style: {
          fontSize: '16px',
          fontFamily: 'Honeywell Sans Web'
        }
      }
    }
  })
}

export function getPressureDataOptions (
  maxPressureData: Array<(number | [(number | string), (number | null)] | null | PointOptionsObject)> = [[null, null]],
  avgpressureData: Array<(number | [(number | string), (number | null)] | null | PointOptionsObject)> = [[null, null]],
  minPressureData: Array<(number | [(number | string), (number | null)] | null | PointOptionsObject)> = [[null, null]]
): Options {
  const seriesOptions: SeriesLineOptions[] = [
    {
      type: 'line',
      name: 'Max Pressure',
      data: maxPressureData,
      lineWidth: 2,
      color: '#B86699',
      pointInterval: 3600 * 1000 * 24 // This forces the x-axis intervals to be 24 hours
    },
    {
      type: 'line',
      name: 'Average Pressure',
      data: avgpressureData,
      lineWidth: 2,
      color: '#64C3FF',
      pointInterval: 3600 * 1000 * 24
    },
    {
      type: 'line',
      name: 'Min Pressure',
      data: minPressureData,
      lineWidth: 2,
      color: '#97E7CC',
      pointInterval: 3600 * 1000 * 24
    }
  ]

  return (
    {
      chart: {
        type: 'line',
        style: {
          fontSize: '16px',
          fontFamily: 'Honeywell Sans Web'
        }
      },
      title: {
        text: ''
      },
      series: seriesOptions,
      legend: {
        itemStyle: {
          fontWeight: '400',
          fontSize: '16px'
        }
      },

      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%d %b %Y %H:%M' // ex- 01 Jan 2016
        },
        title: {
          text: 'Date'
        }
      },
      yAxis: {
        title: {
          text: 'Pressure Reading'
        },
        min: 0
      },

      plotOptions: {
        series: {
          marker: {
            enabled: true,
            radius: 1
          }
        }
      }
    }
  )
}

export function getTemperatureDataOptions (
  avgTemperatureData: Array<(number | [(number | string), (number | null)] | null | PointOptionsObject)> = [[null, null]]
): Options {
  const seriesData: SeriesLineOptions[] = [
    {
      type: 'line',
      name: 'Average Temperature',
      data: avgTemperatureData,
      pointInterval: 3600 * 1000 * 24 // one day
    }
  ]

  return ({
    chart: {
      // type: 'spline'
      type: 'line',
      style: {
        fontSize: '16px',
        fontFamily: 'Honeywell Sans Web'
      }
    },
    title: {
      text: ''
    },
    series: seriesData,
    legend: {
      itemStyle: {
        fontWeight: '400',
        fontSize: '16px'
      }
    },
    xAxis: {
      title: {
        text: 'Date'
      },
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%d %b %Y %H:%M' // ex- 01 Jan 2016
      }

    },
    yAxis: {
      title: {
        text: 'Temperature (F)'
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          radius: 2
        }
      }
    }
  })
}

export function getConsumptionDataOptions (
  consumptionData: any = [[null, null, null]], unit = '', id: string, toolTip: ChannelDropdownData | undefined
): Options {
  const unitText = unit !== '' ? `(${unit})` : ''
  const seriesData: SeriesAreasplineOptions[] = [
    {
      type: 'areaspline',
      name: toolTip?.mainTitle ?? '',
      data: consumptionData,
      lineWidth: 2,
      lineColor: '#97E7CC',
      fillOpacity: 0.1,
      events: {
        legendItemClick: function () {
          return false
        }
      },
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 4,
        fillColor: '#97E7CC',
        lineColor: '#000',
        lineWidth: 1,
        states: {
          hover: {
            enabled: true
          }
        },
        fillOpacity: 1
      }
    }
  ]

  return ({
    chart: {
      type: 'areaspline',
      style: {
        fontSize: '16px',
        fontFamily: 'Honeywell Sans Web'
      }
    },
    title: {
      text: ''
    },
    series: seriesData,
    colors: [
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, '#97E7CC'],
          [1, '#C4C4C400']
        ]
      }
    ],
    legend: {
      itemStyle: {
        fontWeight: '400',
        fontSize: '16px'
      },
      backgroundColor: 'transparent'
    },
    xAxis: {
      title: {
        text: 'Date'
      },
      type: 'datetime',
      startOnTick: true,
      endOnTick: true
    },
    yAxis: {
      title: {
        text: toolTip?.mainTitle ?? ''
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          radius: 2
        },
        connectNulls: false
      }
    },
    // istanbul ignore next
    tooltip: {
      formatter: function () {
        const ctx = this
        return getDataSourceTooltip(ctx, consumptionData, unitText, toolTip?.toolTip ?? [])
      }
    }
  })
}

export function getAmiDataOptions (color: string, ChartData: any, containerRef: any, hrRef: any, titleRef: any, width: number): Options {
  const seriesData: any = [
    {
      name: 'Snapshot Register Reads (%)',
      data: ChartData,
      pointInterval: 3600 * 1000 * 24,
      lineWidth: '1.5px',
      lineColor: color
    }
  ]

  // let width = 300
  // if (containerRef !== null) {
  //   // istanbul ignore else
  //   // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  //   if (containerRef.current && hrRef.current && titleRef.current) {
  //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //     width = containerRef.current.clientWidth - hrRef.current.clientWidth - titleRef.current.clientWidth
  //   }
  // }

  return ({
    chart: {
      type: 'areaspline',
      style: {
        fontSize: '16px',
        fontFamily: 'Honeywell Sans Web'
      },
      backgroundColor: 'transparent',
      height: 100,
      width
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    tooltip: {
      backgroundColor: 'white',
      style: {
        color: 'black',
        fontSize: '14px'
      },
      useHTML: true,
      formatter: function () {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `${convertDateIntoAohStandardUsingDayjs(this.series.data[this.point.index].name)} <br /> Snapshot Register Reads: ${this.series.data[this.point.index].y?.toFixed(2)}%`
      }
    },
    series: seriesData,
    colors: [
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, `${color}44`],
          [1, `${color}00`]
        ]
      },
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, '#5E5E5E00'],
          [1, '#5E5E5E00']
        ]
      }
    ],
    legend: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      visible: false
    },
    yAxis: {
      visible: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          radius: 2
        }
      }
    }
  })
}

const getNetworkSeriesData = (
  kpiData: {
    name: NETWORK_KPI
    color: string
    data: Array<[number, number | null]>
  }): SeriesAreasplineOptions => {
  return {
    type: 'areaspline',
    threshold: 0,
    name: kpiData.name,
    data: kpiData.data,
    lineWidth: 3,
    lineColor: kpiData.color,
    fillOpacity: 0.1,
    events: {
      legendItemClick: function () {
        return false
      }
    },
    marker: {
      enabled: true,
      symbol: 'circle',
      radius: 4,
      fillColor: kpiData.color,
      lineColor: '#000',
      lineWidth: 1,
      states: {
        hover: {
          enabled: true
        }
      },
      fillOpacity: 1
    }
  }
}

export function getNetworkKpiOptions (
  networkData: Array<{
    name: NETWORK_KPI
    color: string
    data: Array<[number, number | null]>
  }>): Options {
  const seriesData: SeriesAreasplineOptions[] = networkData.map((kpiData) => getNetworkSeriesData(kpiData))

  return {
    chart: {
      type: 'areaspline',
      style: {
        fontSize: '16px',
        fontFamily: 'Honeywell Sans Web'
      }
    },
    title: {
      text: ''
    },
    series: seriesData,
    colors: [
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 1,
          y2: 0
        },
        stops: [
          [0, 'rgba(151, 231, 204, 0.4)'],
          [1, 'rgba(151, 231, 204, 0)']
        ]
      },
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, 'rgba(196, 196, 196, 0)'],
          [1, 'rgba(100, 195, 255, 0.4)']
        ]
      },
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 1,
          y2: 0
        },
        stops: [
          [0, 'rgba(245, 102, 162, 0.4)'],
          [1, 'rgba(196, 196, 196, 0)']
        ]
      }
    ],
    legend: {
      enabled: true,
      useHTML: true,
      itemStyle: {
        fontWeight: '400',
        fontSize: '16px'
      },
      backgroundColor: 'transparent',
      labelFormatter: function () {
        const name = this.name
        const info = networkDetailTooltip(name as NETWORK_KPI)
        const color = networkData.find((kpi) => kpi.name === name)?.color ?? '#606063'
        return `<span>
                  <span style="font-size: 17px; color: ${this.visible ? color : '#606063'}">▍</span>
                  <span>${name}</span>
                  <abbr title="${info}" style="text-decoration: none; font-weight: bolder"> ⓘ </abbr>
                </span>`
      },
      align: 'right',
      verticalAlign: 'top',
      y: 5
    },
    xAxis: {
      title: {
        text: 'Date'
      },
      type: 'datetime',
      startOnTick: true,
      endOnTick: true
    },
    yAxis: {
      title: {
        text: 'db or dBm'
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false
        },
        connectNulls: false
      }
    },
    tooltip: {
      outside: true,
      formatter: function () {
        return getTooltipText(this, networkData)
      }
    }
  }
}
