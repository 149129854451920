import React, { useEffect } from 'react'
import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, styled, tableCellClasses } from '@mui/material'
import Pagination from '../../../components/TablePagination'
import { visuallyHidden } from '@mui/utils'
import { type ConnectedProps, connect } from 'react-redux'
import { type RootState } from '../../../store'
import { fetchConfigurationsForStatus, fetchConfigurationsForStatusSearch } from '../redux/actionCreator'
import { type ConfigurationsForStatusState } from '../redux/configurationListForStatusSlice'
import { type ConfigurationObj, type GroupParaObj } from '../services/configurationServiceForStatus'
import AuthError from '../../../components/ErrorComponents/AuthError'
import successBadge from '../../../assets/successBadge.svg'
import progressBadge from '../../../assets/progressBadge.svg'
import failedBadge from '../../../assets/failedBadge.svg'
import notApplicableBadge from '../../../assets/notApplicableBadge.svg'
import { type TemplateDetailsState, changeTemplate } from '../redux/selectedTemplateDetailsSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LanguageStrings from '../../../i18n/locales'
import { useAuth } from 'react-oidc-context'
import { getGroupModel } from '../../asset-configuration/components/getGroupModel'
import notStarted from '../../../assets/notStarted.svg'
import completedBadge from '../../../assets/completedBadge.svg'
import SearchBox from '../../../components/SearchBox'

const ConfigurationListStrings = LanguageStrings().ConfigurationUploadStrings.components.ConfigurationList

const style = {
  width: '99%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

function EnhancedTableHead (props: any): any {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (newOrderBy: keyof ConfigurationObj) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, newOrderBy)
  }

  return (
    <TableHead data-testid="table-header">
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <StyledTableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === 'groups'
              ? headCell.label
              : (
                <TableSortLabel
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  aria-label={headCell.label}
                  data-testid="test-sort"
                  sx={{
                    color: '#FFFFFF', // Set text color to white
                    textDecoration: 'none' // Remove underline
                  }}
                >
                  {headCell.label}
                  {orderBy === headCell.id
                    ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                      )
                    : null}
                </TableSortLabel>
                )
            }
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& th': {
    border: '1px solid #202020'
  }
}))

const headCells: readonly HeadCell[] = [
  {
    id: 'template_name',
    numeric: false,
    disablePadding: false,
    label: ConfigurationListStrings.CONFIGURATION_NAME
  },
  {
    id: 'asset_type_name',
    numeric: false,
    disablePadding: false,
    label: ConfigurationListStrings.CONFIGURATION_TYPE
  },
  {
    id: 'assets_associated',
    numeric: true,
    disablePadding: false,
    label: ConfigurationListStrings.TOTAL_ASSET
  },
  {
    id: 'groups',
    numeric: false,
    disablePadding: false,
    label: ConfigurationListStrings.GROUP_NAME
  },
  {
    id: 'failed_asset_count',
    numeric: false,
    disablePadding: false,
    label: ConfigurationListStrings.Failed
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: ConfigurationListStrings.ACTIVE_STATUS
  },
  {
    id: 'status_summary',
    numeric: false,
    disablePadding: false,
    label: ConfigurationListStrings.COMPLETION_STATUS
  }
]

type Order = 'asc' | 'desc'

interface Sort {
  order: Order
  orderBy: string
}

const ConfigurationList = (props: PropsFromRedux): JSX.Element => {
  const [configList, setConfigList] = React.useState<ConfigurationObj[]>([])
  const [showGroup, setshowGroup] = React.useState<GroupParaObj[]>([])
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({ order: 'asc', orderBy: 'templateName' })
  const [searchValue, setSearchValue] = React.useState<string | null>(null)
  const rowsPerPage = 10
  const [apiToBeCalled, setApiToBeCalled] = React.useState('default')
  const [init, setInit] = React.useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()

  // istanbul ignore next
  useEffect(() => {
    if (apiToBeCalled === 'default') {
      fetchConfigurationList()
    } else if (apiToBeCalled === 'search') {
      handleSearchApi()
    }
  }, [page, sort])

  // istanbul ignore next
  useEffect(() => {
    if (init) {
      if (page > 1) {
        setPage(1)
      } else {
        if (apiToBeCalled === 'default') {
          fetchConfigurationList()
        } else if (apiToBeCalled === 'search') {
          handleSearch()
        }
      }
    } else {
      setInit(true)
    }
  }, [apiToBeCalled])

  useEffect(() => {
    if (
      props.configurationsForStatusState.configurationsWithStatus !== null &&
      props.configurationsForStatusState.configurationsWithStatus.configuration_status
        .length > 0
    ) {
      setConfigList(
        props.configurationsForStatusState.configurationsWithStatus
          .configuration_status
      )
    }
  }, [props.configurationsForStatusState.configurationsWithStatus])

  const fetchConfigurationList = (): void => {
    page >= 1 &&
      props.fetchConfigurationsForStatus(
        page - 1,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        auth.user?.profile.customer as string
      )
  }

  const handleRowClick = (templateId: string, templateName: string): void => {
    const templateDetails: TemplateDetailsState = {
      template_id: templateId,
      template_name: templateName
    }
    dispatch(changeTemplate(templateDetails))
    navigate('/configuration-details')
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof ConfigurationObj
  ): void => {
    const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
    const toggledOrder = isAsc ? 'desc' : 'asc'
    setSort({ order: toggledOrder, orderBy: newOrderBy })
  }

  const getCompletionStatusBadge = (status: string): JSX.Element => {
    switch (status) {
      case 'Success':
        return <img data-testid="successBadge" src={successBadge} />
      case 'In Progress':
        return <img data-testid="progressBadge" src={progressBadge} />
      case 'Not Started':
        return <img data-testid="notStartedBadge" src={notStarted} />
      case 'Not Applicable':
        return <img data-testid="notApplicableBadge" src={notApplicableBadge} />
      case 'Failure':
        return <img data-testid="failedBadge" src={failedBadge} />
      case 'Completed':
        return <img data-testid="successBadge" src={completedBadge} />
      default:
        return <img data-testid="notApplicableBadge" src={notApplicableBadge} />
    }
  }

  const handleConfigurationtable = (): JSX.Element => {
    if (
      props.configurationsForStatusState.configurationsWithStatus !== null &&
      props.configurationsForStatusState.configurationsWithStatus
        .configuration_status.length > 0 &&
      props.configurationsForStatusState.httpStatus === 200
    ) {
      return (
      <Paper
            sx={{
              width: '100%',
              mb: 2,
              backgroundColor: '#272727',
              boxShadow: 'unset',
              color: '#F0F0F0',
              marginBottom: '0'
            }}
          >
            <TableContainer className="device-table">
              <Table
                sx={{ minWidth: 750, color: '#f0f0f0' }}
                aria-labelledby="tableTitle"
                size={'small'}
              >
                <EnhancedTableHead
                  order={sort.order}
                  orderBy={sort.orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={configList.length}
                />
                <TableBody>
                  {configList.length > 0 &&
                    configList.map((row: ConfigurationObj, index: number) => {
                      const labelId = `enhanced-table-checkbox-${row.template_id}`

                      return (
                        <StyledTableRow
                          hover
                          tabIndex={-1}
                          key={row.template_id}
                          aria-label="devices-list-row"
                          data-testId={`row-${index}`}
                        >
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            aria-label="device-name"
                            data-testid="device-name"
                            onClick={() => {
                              handleRowClick(row.template_id, row.template_name)
                            }}
                            sx={{
                              color: '#64C3FF !important',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '25vw',
                              cursor: 'pointer'
                            }}
                          >
                            {row.template_name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.asset_type_name.toUpperCase()}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.assets_associated}
                          </StyledTableCell>
                          <StyledTableCell>
                            {(row.groups === null ||
                              Object.keys(row.groups).length === 0)
                              ? ''
                              : row.groups.length === 1
                                ? <span data-testid={`assigned-single-group-${index}`} onClick={() => { setshowGroup(row.groups) }} style={{ color: '#64C3FF', cursor: 'pointer' }}>{row.groups[0]?.group_name}</span>
                                : (
                                  <span
                                    data-testid={`assigned-group-${index}`}
                                    onClick={() => {
                                      setshowGroup(row.groups)
                                    }}
                                    style={{ color: '#64C3FF', cursor: 'pointer' }}
                                  >
                                    {row.groups[0]?.group_name}+{row.groups.length - 1}
                                  </span>
                                  )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.failed_asset_count}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color:
                                row.status.toUpperCase() === 'ACTIVE'
                                  ? '#68D560 !important'
                                  : '#FF644C !important'
                            }}
                          >
                            {(row.status[0].toUpperCase() +
                              row.status.slice(1).toLowerCase())}
                          </StyledTableCell>
                          <StyledTableCell>
                            <div style={{ height: '20px' }}>
                              {getCompletionStatusBadge(row.status_summary)}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {props.configurationsForStatusState.configurationsWithStatus.total_count > 10 && (<Pagination
              count={
                props.configurationsForStatusState.configurationsWithStatus.total_count
              }
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              siblingCount={1}
              boundaryCount={1}
            />)}
          </Paper>
      )
    } else if (props.configurationsForStatusState.isLoading) {
      return (
      <div className="CircularprogressAmi">
        <CircularProgress />
      </div>
      )
    } else if (props.configurationsForStatusState.httpStatus === 401) {
      return <div className="authError"><AuthError errorMessage="Unauthorized" /></div>
    } else if (props.configurationsForStatusState.httpStatus === 403) {
      return <div className="authError"><AuthError errorMessage="accessForbidden" /></div>
    } else if (props.configurationsForStatusState.httpStatus === 404) {
      return <div className="authError"><AuthError errorMessage="NoDataPresent" /></div>
    } else if (
      (props.configurationsForStatusState.configurationsWithStatus !== null &&
      props.configurationsForStatusState.configurationsWithStatus
        .configuration_status.length === 0 &&
      props.configurationsForStatusState.httpStatus === 200) ||
    props.configurationsForStatusState.error === 'No data Found for given tenantId'
    ) {
      return <div className="authError"><AuthError errorMessage="NoDataPresent" /></div>
    } else if (props.configurationsForStatusState.httpStatus === 400) {
      return <div className="authError"><AuthError errorMessage="invalidSearchInput" /></div>
    } else {
      return (
      <div className="authError"><AuthError errorMessage="cannotFetch" retry={fetchConfigurationList} /></div>
      )
    }
  }

  // istanbul ignore next
  const handleSearchApi = (): void => {
    props.fetchConfigurationsForStatusSearch(searchValue ?? '', page, rowsPerPage)
  }

  /* istanbul ignore next */
  const handleSearch = (): void => {
    if (apiToBeCalled === 'search') {
      if (page === 1) {
        handleSearchApi()
      } else {
        setPage(1)
      }
    } else {
      setApiToBeCalled('search')
    }
  }

  return (
      <>
        <Box sx={style} className="devices">
        <div className='searchSection' style={{ width: '100%', backgroundColor: '#272727' }}>
          <Paper className='searchBoxComponent'
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
          >
            <SearchBox immediate={true} debounce={true} placeholder='Search using Name, Type, Asset, Group Name, Status' sx={{ width: '445px', marginLeft: '-15px' }}
            emptyEnterFallback={() => {
              setApiToBeCalled('default')
            }} searchVal={searchValue} setSearchVal={setSearchValue}
              performAction={handleSearch} />
          </Paper>
        </div>
        {handleConfigurationtable()}
        </Box>
        {showGroup.length > 0
          ? (
              getGroupModel(showGroup, () => {
                setshowGroup([])
              })
            )
          : (
          <></>
            )}
      </>
  )
}

interface DispatchToProps {
  fetchConfigurationsForStatus: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    tenantId: string
  ) => void
  fetchConfigurationsForStatusSearch: (search: string, page: number, size: number) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchConfigurationsForStatus: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    tenantId: string
  ) =>
    dispatch(
      fetchConfigurationsForStatus(
        page,
        size,
        sortdir,
        sortfield,
        tenantId
      )
    ),
  fetchConfigurationsForStatusSearch: (
    search: string, page: number, size: number
  ) =>
    dispatch(
      fetchConfigurationsForStatusSearch(
        search, page, size
      )
    )
})

interface StateToProps {
  configurationsForStatusState: ConfigurationsForStatusState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  configurationsForStatusState: state.configurationsForStatus
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ConfigurationList)
