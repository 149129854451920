import React, { useEffect } from 'react'
import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material'
import { type HistoryADSData } from '../../types'
import { fetchKpiHistoryADS } from '../../redux/actionCreators'
import { type HistoryADSTableState } from '../../redux/HistoryADSTableSlice'
import { type RootState } from '../../../../store'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import Pagination from '../../../../components/TablePagination'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import { type ToggleOption } from '../../../../components/GenericToggleButton'
import dayjs from 'dayjs'
import { getDetailKeyword } from './kpiUtils'
import { type ExportADSKpiDetailsState } from '../../redux/exportADSKpiDetailsSlice'
import { getColumnsByKpi } from './HistoryTableCustomColumns'
import { convertDateIntoAohStandardUsingDayjs } from '../../../../customHooks/parseDate'

const style = {
  width: '100%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

type Order = 'asc' | 'desc'
interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

function EnhancedTableHead (props: any): any {
  const { headCells } = props

  return (
    <TableHead data-testid="table-header">
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <StyledTableCell
            sx={{ whiteSpace: 'nowrap' }}
            key={headCell.id}
            align={'left'}
            padding={'normal'}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

interface Sort {
  order: Order
  orderBy: string
}

const StyledTableRow = styled(TableRow)(() => ({
  '& th': {
    border: '1px solid #202020'
  }
}))

interface ParentProps {
  selectedBar: ToggleOption
  selectedDate: dayjs.Dayjs
  rowPerPage: number
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  sort: Sort
  setSort: React.Dispatch<React.SetStateAction<Sort>>
}

function HistoryTableComponent (props: AllProps): JSX.Element {
  const [assets, setAssets] = React.useState<HistoryADSData[]>([])
  const selectedKpi = useSelector((state: RootState) => state.kpiType)
  const [detailKeyword, detailService] = getDetailKeyword(selectedKpi, props.selectedBar.value)

  const headCells = getColumnsByKpi(detailKeyword)

  const fetchAssets = (): void => {
    if (props.page >= 1) {
      props.fetchKpiHistoryADS(detailKeyword, dayjs(props.selectedDate).format('YYYY-MM-DD'), props.page - 1, props.rowPerPage, detailService)
    }
  }

  useEffect(() => {
    if (props.KpiHistoryADSState.data !== undefined) {
      setAssets(props.KpiHistoryADSState.data.assetDetails)
    }
  }, [props.KpiHistoryADSState])

  if (props.KpiHistoryADSState.data !== undefined && props.KpiHistoryADSState.httpStatus === 200 && props.KpiHistoryADSState.data.assetDetails.length > 0) {
    return (
      <>
        <Box sx={style} className="devices">
          <Paper
            sx={{
              width: '100%',
              mb: 2,
              backgroundColor: '#272727',
              boxShadow: 'unset',
              color: '#F0F0F0',
              marginBottom: '0'
            }}
          >
            <TableContainer className="device-table">
              <Table
                sx={{ minWidth: 750, color: '#f0f0f0' }}
                aria-labelledby="tableTitle"
                size={'small'}
              >
                <EnhancedTableHead
                  headCells={headCells}
                />
                <TableBody>
                  {assets.map((row, index) => {
                    return (
                        <StyledTableRow
                          hover
                          tabIndex={-1}
                          key={index}
                          aria-label="asset-list-row"
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          {
                            headCells.map((cell, idx) => {
                              return (
                                <StyledTableCell data-testid={`row-${index}-col-${idx}`} key={cell.id} sx={{ padding: '1.2px 16px', color: '#64C3FF' }}>
                                  {cell.isDate ? convertDateIntoAohStandardUsingDayjs(row[cell.id as keyof typeof row]) : row[cell.id as keyof typeof row]}
                                </StyledTableCell>
                              )
                            })
                          }
                        </StyledTableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {props.KpiHistoryADSState.data.totalCount > 10 && (<Pagination
              count={props.KpiHistoryADSState.data.totalCount}
              page={props.page}
              setPage={props.setPage}
              rowsPerPage={props.rowPerPage}
              siblingCount={1}
              boundaryCount={1}
            />)}
          </Paper>
        </Box>
      </>
    )
  } else if (props.KpiHistoryADSState.isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }} className="CircularprogressAmi">
        <CircularProgress />
      </div>
    )
  } else if ((props.KpiHistoryADSState.data !== undefined && props.KpiHistoryADSState.httpStatus === 200 && props.KpiHistoryADSState.data.assetDetails.length === 0) || (props.KpiHistoryADSState.errorMessage === 'No data found' && props.KpiHistoryADSState.httpStatus === 404)) {
    return (
      <div className="authError">
        <AuthError errorMessage="NoDataPresent" />
      </div>
    )
  } else if (props.KpiHistoryADSState.httpStatus === 401) {
    return (
      <div className="authError">
        <AuthError errorMessage="Unauthorized" />
      </div>
    )
  } else if (props.KpiHistoryADSState.httpStatus === 403) {
    return (
      <div className="authError">
        <AuthError errorMessage="accessForbidden" />
      </div>
    )
  } else {
    return (
      <div className="authError" style={{ margin: '1em' }}>
        <AuthError errorMessage="cannotFetch" retry={fetchAssets} />
      </div>
    )
  }
}

interface DispatchToProps {
  fetchKpiHistoryADS: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchKpiHistoryADS: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => dispatch(fetchKpiHistoryADS(kpiType, inputDateRead, page, size, service))
})

interface StateToProps {
  KpiHistoryADSState: HistoryADSTableState
  exportADSKpiDetailData: ExportADSKpiDetailsState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  KpiHistoryADSState: state.KpiHistoryADSState,
  exportADSKpiDetailData: state.exportADSKpiDetailsData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = ParentProps & PropsFromRedux

export default connector(HistoryTableComponent)
