/* eslint-disable @typescript-eslint/consistent-type-imports */
import React, { useEffect, useState } from 'react'
import Modal from '../../../components/Modal'
import {
  GenericDropdown,
  type Option
} from '../../../components/GenericDropdown'
import { CircularProgress, TextField } from '@mui/material'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import { fetchGroupListData, postAssetGroup, putAssetGroup } from '../redux/actionCreators'
import { type GroupCreationRequest } from '../services/createAssetGroupService'
import { type GroupUpdationRequest } from '../services/updateAssetGroupService'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { type UpdationState } from '../redux/updateGroupSlice'
import { type CreateGroupState } from '../redux/createGroupSlice'
import { useAuth } from 'react-oidc-context'
import { AssetGroupState } from '../redux/groupListSlice'
import LanguageStrings from '../../../i18n/locales'
import { isValidDesc, isValidGroupNameText } from '../../../utils/validator'

const AssignGroupStrings = LanguageStrings().AssetConfigurationStrings.components.ConfigAssignGroupPopup
const AssignGroupPopUp = (props: AllProps): JSX.Element => {
  const [groupOptions, setGroupOptions] = useState<Option[]>([])
  const [group, setGroup] = useState('')
  const [newGroup, setNewGroup] = useState('')
  const [description, setDescription] = useState('')
  const [error, setError] = useState('')
  const [descError, setDescError] = useState('')
  const [created, setCreated] = useState(false)
  const [isCreatingGroup, setIsCreatingGroup] = useState(false)
  const [showSuccessPopUp, setshowSuccessPopUp] = useState(false)
  const auth = useAuth()
  const assetType: any = useSelector(
    (state: RootState) => state.deviceDetail.deviceData
  )

  useEffect(() => {
    props.fetchGroupListData(assetType !== null ? assetType[0].deviceType : '', group, auth.user?.profile.customer as string)
  }, [group])

  useEffect(() => {
    let transformedArray: React.SetStateAction<Option[]> = []
    if (props.groupListData.groupListData !== null && props.groupListData.groupListData !== undefined) {
      transformedArray = props.groupListData.groupListData.map((item) => ({
        value: item.asset_group_id,
        label: item.group_name
      }))
    }
    setGroupOptions(transformedArray)
  }, [props.groupListData])

  // istanbul ignore next
  const handleChooseGroup = (e: any): void => {
    if (isCreatingGroup) {
      setDescription('')
      setNewGroup('')
    }
    setGroup(groupOptions.filter(grp => grp.value === e.target.value)[0].value)
    setIsCreatingGroup(false)
    setError('')
  }

  const isGroupNameNotValid = (groupName: string): boolean => {
    return (!isValidGroupNameText(groupName) || (groupName.length > 60))
  }

  const isDescriptionNotValid = (desc: string): boolean => {
    return ((!isValidDesc(desc) || desc.length > 200) && desc.length !== 0)
  }

  const handleDescriptionChange = (e: any): void => {
    const input = e.target.value
    setDescription(input)
    if (isDescriptionNotValid(input)) {
      setDescError('Enter a valid description (Alphanumeric and max 200 characters)')
    } else {
      setDescError('')
      setshowSuccessPopUp(false)
    }
  }

  const handleCreateGroupClick = (): void => {
    setGroup('')
    setIsCreatingGroup(true)
  }

  const handelNewGroupCreation = (e: { target: { value: any } }): void => {
    const input = e.target.value
    setNewGroup(input)
    if (input.length === 0) {
      setError('Group name cannot be Empty')
    } else if (isGroupNameNotValid(input)) {
      setError('Enter a valid name (Alphanumeric and max 60 characters)')
    } else {
      setError('')
      setshowSuccessPopUp(false)
    }
  }

  const handleClickPositive = (): void => {
    // istanbul ignore next
    if (isCreatingGroup && newGroup === '') {
      return
    }

    // istanbul ignore else
    if (error === '') {
      setshowSuccessPopUp(true)
    }
  }

  const isAssignButtonEnabled = (): boolean => {
    return error.length > 0 || descError.length > 0
  }

  // istanbul ignore next
  const handleGenericDropdownWithFailure = (): JSX.Element => {
    if (props.groupListData.isLoading) {
      return <>
        <div className='CircularprogressAmi'>
          <CircularProgress />
        </div>
      </>
    } else if ((props.groupListData.httpStatus === 200 && props.groupListData.groupListData !== undefined)) {
      return (
        <>
          <GenericDropdown
            dataTestId="select-group"
            width="32em"
            options={groupOptions}
            value={group}
            onChange={handleChooseGroup}
            label={'Select Group'}
            allowInput={true}
            setState={setGroup} />
        </>)
    } else if (props.groupListData.httpStatus === 401) {
      return (
        <div className='errorCompInsideModal'><AuthError errorMessage="Unauthorized" /></div>
      )
    } else if (props.groupListData.httpStatus === 403) {
      return (
        <div className='errorCompInsideModal'><AuthError errorMessage="accessForbidden" /></div>
      )
    } else {
      return (
        <div className='errorCompInsideModal'><AuthError errorMessage="cannotFetchGroups" /></div>
      )
    }
  }

  const handleClickPositiveCreateAndUpdate = (): void => {
    const finalGroupName = (group === '') ? ((newGroup === null) ? props.fileName : newGroup) : group
    if (isCreatingGroup) {
      props.postAssetGroup({
        group_name: finalGroupName,
        description,
        asset_type: assetType[0].deviceType,
        assets: props.selected
      })
    } else {
      props.putAssetGroup({
        asset_group_id: finalGroupName,
        assets: props.selected
      })
    }
  }

  // istanbul ignore next
  const handleSuccessCreate = (): JSX.Element => {
    if (props.createAssetGroup.isLoading || props.updateAssetGroup.isLoading) {
      return (
        <Modal title={AssignGroupStrings.Create_Group}>
          <div className='CircularprogressAmi'>
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (created && ((isCreatingGroup && props.createAssetGroup.httpStatus === 200) || (!isCreatingGroup && props.updateAssetGroup.httpStatus === 200))) {
      return (
        <Modal buttonTypeNegative='hollow' title={isCreatingGroup && props.createAssetGroup.httpStatus === 200 ? AssignGroupStrings.Create_Successfull_Title : AssignGroupStrings.Assign_Successfull_Title} LabelPositive='Ok' onClickPositive={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }}>
          <p style={{ paddingTop: '1.2em' }}>{isCreatingGroup && props.createAssetGroup.httpStatus === 200 ? AssignGroupStrings.Group_Created_Successfully : AssignGroupStrings.Group_has_been_assigned_successfully}</p>
        </Modal>
      )
    } else if ((props.createAssetGroup.httpStatus === 401) || (props.updateAssetGroup.httpStatus === 401)) {
      return (
        <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
          onClickNegative={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }} title={AssignGroupStrings.Assign_Unsuccessfull_Title}>
          <div className='authError'>
            <AuthError className={'errorCompInsideGroupModal'} errorMessage="Unauthorized" />
          </div>
        </Modal>
      )
    } else if ((props.createAssetGroup.httpStatus === 403) || (props.updateAssetGroup.httpStatus === 403)) {
      return (
        <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
          onClickNegative={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }} title={AssignGroupStrings.Assign_Unsuccessfull_Title}>
          <div className='authError'>
            <AuthError className={'errorCompInsideGroupModal'} errorMessage="accessForbidden" />
          </div>
        </Modal>
      )
    } else {
      return (<Modal title={'Error!!!'} onClose={() => { props.onAssetGroupPopUpClose() }}>
        <div className='errorCompInsideGroupModal'><AuthError errorMessage="cannotAssginGroup" /></div>
      </Modal>
      )
    }
  }
  return (
    <div>
      {(!showSuccessPopUp || (showSuccessPopUp && error !== '')) && <Modal
        buttonTypeNegative="hollow"
        title={AssignGroupStrings.Assign_Group}
        LabelPositive="Assign"
        LabelNegative="Cancel"
        height={'fit-content'}
        sx={{ maxHeight: '90vh', overflowY: 'auto', overflowX: 'hidden' }}
        onClickPositive={() => {
          handleClickPositive()
        }}
        onClickNegative={() => {
          props.onAssetGroupPopUpClose()
        }}
        onClose={() => {
          props.onAssetGroupPopUpClose()
        }}
        positiveDisabled={isAssignButtonEnabled()} // Pass the state to disable/enable the Assign button
      >
        <div style={{ marginTop: '3em' }} className='authError'>
          {handleGenericDropdownWithFailure()}
        </div>
        <div
          style={{
            marginTop: '1.5em',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          or
        </div>
        <div style={{ marginTop: '1.5em', width: '32em' }}>
          <div style={{ marginBottom: '1em' }}>{AssignGroupStrings.Create_New_Group}</div>
          <TextField
            variant="standard"
            sx={{
              backgroundColor: '#505050',
              borderRadius: '4px',
              paddingLeft: '4px'
            }}
            fullWidth
            placeholder="Enter Group Name"
            value={newGroup}
            onChange={handelNewGroupCreation}
            onClick={handleCreateGroupClick}
            inputProps={{
              sx: {
                color: 'white'
              }
            }}
          />
        </div>
        {error.length > 0 && (
            <div style={{ color: 'red', marginTop: '0.5em' }}>{error}</div>
        )}
        <div
          style={{ marginTop: '1.5em', marginBottom: '2.5em', width: '32em' }}
        >
          <div style={{ marginBottom: '1em' }}>Description</div>
          <TextField
            variant="filled"
            sx={{ backgroundColor: '#505050', borderRadius: '4px' }}
            multiline
            rows={3}
            fullWidth
            placeholder="Multiple lines of text go here"
            value={description}
            onChange={handleDescriptionChange}
            onClick={handleCreateGroupClick}
            inputProps={{
              sx: {
                color: 'white',
                '&::placeholder': {
                  color: '#D0D0D0',
                  fontStyle: 'italic'
                }
              }
            }}
          />
        {descError.length > 0 && (
            <div style={{ color: 'red', marginTop: '0.5em' }}>{descError}</div>
        )}
        </div>
      </Modal>}
      {showSuccessPopUp && error === '' && (
        <Modal
          buttonTypeNegative="hollow"
          title={AssignGroupStrings.Assign_Group}
          LabelPositive='Confirm'
          LabelNegative='Cancel'
          onClickPositive={() => {
            setshowSuccessPopUp(false)
            setCreated(true)
            handleClickPositiveCreateAndUpdate()
          }}
          onClickNegative={() => {
            setshowSuccessPopUp(false)
          }}
          onClose={() => {
            setshowSuccessPopUp(false)
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {isCreatingGroup && props.createAssetGroup.httpStatus === 200 ? AssignGroupStrings.Assign_Group_To_Selected_Asset : AssignGroupStrings.Create_Group_To_Selected_Asset}
          </p>
        </Modal>
      )}
      {created && handleSuccessCreate()}
    </div>
  )
}

interface DispatchToProps {
  fetchGroupListData: (
    assetType: string,
    groupName: string,
    tenantId: string,
  ) => void
  postAssetGroup: (GroupCreationRequestBody: GroupCreationRequest) => void
  putAssetGroup: (GroupUpdationRequestBody: GroupUpdationRequest) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchGroupListData: (
    assetType: string,
    groupName: string,
    tenantId: string
  ) => dispatch(fetchGroupListData(assetType, groupName, tenantId)),
  postAssetGroup: (GroupCreationRequestBody: GroupCreationRequest) => dispatch(postAssetGroup(GroupCreationRequestBody)),
  putAssetGroup: (GroupUpdationRequestBody: GroupUpdationRequest) => dispatch(putAssetGroup(GroupUpdationRequestBody))

})

interface StateToProps {
  groupListData: AssetGroupState
  fileName: string
  createAssetGroup: CreateGroupState
  updateAssetGroup: UpdationState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  groupListData: state.groupListData,
  fileName: state.deviceInfo.message?.data.fileName,
  createAssetGroup: state.createGroup,
  updateAssetGroup: state.updateGroup

})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

interface ParentProps {
  onAssetGroupPopUpClose: () => void
  selected: string[]
}
type AllProps = PropsFromRedux & ParentProps

export default connector(AssignGroupPopUp)
