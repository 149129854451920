/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import {
  Box,
  Breadcrumbs,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Radio,
  TextField,
  TextareaAutosize,
  Typography
} from '@mui/material'
import LanguageStrings from '../../../../i18n/locales'
import Tooltip, {
  type TooltipProps,
  tooltipClasses
} from '@mui/material/Tooltip'
import React, { useState } from 'react'
import { GenXDropdown, type Option } from '../../../../components/GenXDropdown'
import { Button } from '../../../../components/Button'
import { useNavigate } from 'react-router-dom'
import {
  CreateSFTPConfigurationservice,
  getConfigurationDropdownMetadata,
  getPublicKeyData,
  TestConnectionService
} from '../../redux/actionCreators'
import { type PublicKeyDataState } from '../../redux/getPublicKeySlice'
import { type CreateSFTPConfigurationState } from '../../redux/CreateSftpConfigurationSlice'
import { type ConfigurationMetadataDropDownState } from '../../redux/configurationMetaDataSlice'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../../store'
import { type SFTPConfigurationData } from '../../types'
import Modal from '../../../../components/Modal'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import '../ReportSchedulerComponents/scheduler.css'
import {
  isValidName,
  isValidPort,
  isValidFolderPath,
  isValidDesc,
  isValidNameEdit
} from '../../../../utils/validator'
import { isValidIp } from '../../../data-publisher/components/addConfig/fileValidation'
import MoreInfo from '../../../../assets/More-Info.svg'
import { styled } from '@mui/material/styles'
import forge from 'node-forge'
import { GenericTextarea } from '../../../../components/GenericTextarea'
import { type testConnectionState } from '../../redux/testConnectionSlice'
import Notification from '../../../../components/Notification'

const CreateSFTPStrings =
  LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings
    .SchedulerStrings.CreateSFTPFormStrings

const CreateSFTPButtonStrings =
  LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings
    .SchedulerStrings.CreateSchedulerFormStrings

const style = {
  width: '99%',
  borderRadius: '10px',
  border: '1px solid #101010',
  background: '#272727',
  boxShadow: '1px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  fontSize: '16px',
  marginBottom: '16px',
  padding: '24px'
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 270,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    fontFamily: 'Honeywell Sans Web'
  }
}))

interface SftpStateType {
  connectionName: string
  description: string
  protocolTypeId: string
  protocolName: string
  ipAddress: string
  port: string
  userName: string
  password: string
  sshKey: string
  folderPath: string
  publicKey: string
  url: string
  apiToken: string
}

const CreateSftpConfiguration = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const [protocolSpecificationName, setProtocolSpecificationName] =
    React.useState('')
  const [description, setDescription] = React.useState('')
  const [selectProtocol, setselectProtocol] = React.useState<Option | null>(
    null
  )
  const [selectProtocolOptions, setSelectProtocolOptions] = React.useState<
  Option[]
  >([])
  const [protocolName, setProtocolName] = React.useState('')
  const [ipAddress, setIpAddress] = React.useState('')
  const [port, setPort] = React.useState('')
  const [userName, setUserName] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [SSHKey, setSSHKey] = React.useState('')
  const [PublicKeystate, setPublicHKeystate] = React.useState('')
  const [folderPath, setFolderPath] = React.useState('')
  const [confirm, setconfirm] = React.useState(false)
  const [showSuccessPopUp, setshowSuccessPopUp] = React.useState(false)
  const [sftpAuthentication, setSftpAuthentication] = React.useState('ssh')
  const [displayMaskValue, setDisplayMaskValue] = React.useState('')
  const [publicKeyMaskValue, setPublicKeyMaskValue] = React.useState('')
  const [apiTokenMaskValue, setApiTokenMaskValue] = React.useState('')
  const [selectedFileType, setSelectedFileType] = useState<'Zipped' | 'Encrypted' | ''>('')
  const [apiToken, setApiToken] = React.useState('')
  const [url, setUrl] = React.useState('')
  const [reqError, setReqError] = React.useState<boolean>(false)
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [isTestConnectionSuccessful, setIsTestConnectionSuccessful] = React.useState(false)
  const DropDownMetaData = useSelector(
    (state: RootState) =>
      state?.ConfigurationMetadata?.configurationMetadata?.protocols
  )
  const ProocolHttpStatus = useSelector(
    (state: RootState) => state?.SFTPConfigurationData
  )
  const publicKey = useSelector(
    (state: RootState) => state.PublicKeyData.PublicKeyData
  )

  const initialState: SftpStateType = {
    connectionName: '',
    description: '',
    protocolTypeId: '',
    protocolName: '',
    ipAddress: '',
    port: '',
    userName: '',
    password: '',
    sshKey: '',
    folderPath: '',
    publicKey: '',
    url: '',
    apiToken: ''
  }

  const [addState, dispatch] = React.useReducer(
    (curVal: SftpStateType, newVal: SftpStateType) => ({ ...curVal, ...newVal }),
    initialState
  )

  React.useEffect(() => {
    props.getConfigurationDropdownMetadata()
    props.getPublicKeyData()
  }, [])

  React.useEffect(() => {
    if (DropDownMetaData !== undefined && DropDownMetaData?.length > 0) {
      const ProtocolOption: Option[] = []
      DropDownMetaData?.forEach((item: any) => {
        ProtocolOption.push({
          value: item.protocolTypeId,
          label: item.protocolTypeName
        })
      })
      setSelectProtocolOptions(ProtocolOption)
    }
  }, [DropDownMetaData])

  React.useEffect(() => {
    if (props.TestConnectionData.httpStatus === 200) {
      setIsTestConnectionSuccessful(true)
    }
  }, [props.TestConnectionData])

  const handleconnectionName = (name: string, value: string): void => {
    setProtocolSpecificationName(value)
    dispatch({ ...addState, connectionName: value })
  }

  const handleDescription = (name: string, value: string): void => {
    setDescription(value)
    dispatch({ ...addState, description: value })
  }

  const handleProtocol = (selectedValue: Option): void => {
    setselectProtocol(selectedValue)
    setIsTestConnectionSuccessful(false)
    dispatch({ ...addState, protocolTypeId: selectedValue.value })
  }

  const handleProtocolName = (name: string, value: string): void => {
    setProtocolName(value)
    dispatch({ ...addState, protocolName: value })
  }

  const handleUrl = (value: string): void => {
    setUrl(value)
    if (value.includes('{token}')) {
      const publicKeyObj = forge.pki.publicKeyFromPem(
        publicKey !== undefined ? publicKey : ''
      )
      dispatch({
        ...addState,
        url: forge.util.encode64(
          publicKeyObj.encrypt(value, 'RSA-OAEP', {
            md: forge.md.sha256.create()
          })
        )
      })
    }
  }

  const handleIPAddress = (name: string, value: string): void => {
    setIpAddress(value)
    dispatch({ ...addState, ipAddress: value })
  }

  const handlePortNumber = (name: string, value: string): void => {
    setPort(value)
    dispatch({ ...addState, port: value })
  }

  const handleUserName = (name: string, value: string): void => {
    setUserName(value)
    const publicKeyObj = forge.pki.publicKeyFromPem(
      publicKey !== undefined ? publicKey : ''
    )
    dispatch({
      ...addState,
      userName: forge.util.encode64(
        publicKeyObj.encrypt(value, 'RSA-OAEP', {
          md: forge.md.sha256.create()
        })
      )
    })
  }

  const handleProtocolFields = (): JSX.Element => {
    if (selectProtocol?.label === 'SFTP') {
      return (
        <>
          <div className='connection-grid-field'>
              <span className='connection-setting-field-title'>
                {CreateSFTPStrings.protocolName}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <TextField
                id="configuration-Pname"
                variant="outlined"
                data-testId="configuration-Pname"
                placeholder="Enter Protocol Name"
                InputLabelProps={{
                  disabled: true
                }}
                value={protocolName}
                onChange={(e) => {
                  handleProtocolName('protocolName', e.target.value)
                  setIsTestConnectionSuccessful(false)
                }}
                size="small"
                autoComplete="off"
                className="configuration-name-class"
                sx={{
                  width: '270px',
                  color: '#fffff !important'
                }}
              />
              {addState.protocolName !== '' &&
                !isValidNameEdit(addState.protocolName) && (
                  <div className="error-response">
                    <span className="error-upload-message">
                      {CreateSFTPButtonStrings.enterValidText}
                    </span>
                  </div>
              )}
              {reqError && !addState.protocolName && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPButtonStrings.required}
                  </span>
                </div>
              )}
              {addState.protocolName !== '' &&
                !isValidlength(addState.protocolName) && (
                  <div className="error-response">
                    <span className="error-upload-message">
                      {CreateSFTPButtonStrings.enterMoreText}
                    </span>
                  </div>
              )}
          </div>
          <div className='connection-grid-field'>
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.SFTPIPAddress}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <TextField
              id="configuration-Ip"
              variant="outlined"
              data-testId="configuration-Ip"
              placeholder="Enter IP Address"
              InputLabelProps={{
                disabled: true
              }}
              value={ipAddress}
              onChange={(e) => {
                handleIPAddress('ipAddress', e.target.value)
                setIsTestConnectionSuccessful(false)
              }}
              size="small"
              autoComplete="off"
              className="configuration-name-class"
              sx={{
                width: '270px',
                color: '#fffff !important'
              }}
            />
            {reqError && !addState.ipAddress && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.required}
                </span>
              </div>
            )}
            {addState.ipAddress !== '' &&
              !isValidIp(addState.ipAddress) && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPStrings.ValidIP}
                  </span>
                </div>
            )}
          </div>
          <div className='connection-grid-field'>
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.SFTPPort}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <TextField
              id="configuration-port"
              variant="outlined"
              data-testId="configuration-port"
              placeholder="Enter Port"
              InputLabelProps={{
                disabled: true
              }}
              inputProps={{ maxLength: 5 }}
              value={port}
              onChange={(e) => {
                handlePortNumber('port', e.target.value)
                setIsTestConnectionSuccessful(false)
              }}
              size="small"
              autoComplete="off"
              className="configuration-name-class"
              sx={{
                width: '270px',
                color: '#fffff !important'
              }}
            />
            {reqError && !addState.port && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.required}
                </span>
              </div>
            )}
            {addState.port !== '' && !isValidPort(addState.port) && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPStrings.ValidPort}
                </span>
              </div>
            )}
          </div>
          <div className='connection-grid-field'>
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.username}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <TextField
              id="configuration-username"
              variant="outlined"
              data-testId="configuration-username"
              placeholder="Enter Username"
              InputLabelProps={{
                disabled: true
              }}
              value={userName}
              onChange={(e) => {
                handleUserName('userName', e.target.value)
                setIsTestConnectionSuccessful(false)
              }}
              size="small"
              autoComplete="off"
              className="configuration-name-class"
              sx={{
                width: '270px',
                color: '#fffff !important'
              }}
            />
            {reqError && !userName && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.required}
                </span>
              </div>
            )}
            {userName !== '' && !isValidUserNameLength(userName) && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.username_validate_msg}
                </span>
              </div>
            )}
            {userName !== '' && !isValidName(userName) && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.username_validate_msg}
                </span>
              </div>
            )}
          </div>
          <div className='connection-grid-field'>
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.folderPath}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <TextField
              id="configuration-Folder-Path"
              variant="outlined"
              data-testId="configuration-Folder-Path"
              placeholder="Enter Folder Path"
              InputLabelProps={{
                disabled: true
              }}
              value={folderPath}
              onChange={(e) => {
                handleFolderPath('folderPath', e.target.value)
                setIsTestConnectionSuccessful(false)
              }}
              size="small"
              autoComplete="off"
              className="configuration-name-class"
              sx={{
                width: '270px',
                color: '#fffff !important'
              }}
            />
            {reqError && !addState.folderPath && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.required}
                </span>
              </div>
            )}
            {addState.folderPath !== '' &&
              !isValidFolderPath(addState.folderPath) && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPStrings.folderPath_error_msg}
                  </span>
                </div>
            )}
            {addState.folderPath !== '' &&
              !isValidPathlength(addState.folderPath) && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPStrings.FolderPath_validate_msg}
                  </span>
                </div>
            )}
          </div>
        </>
      )
    } else if (selectProtocol?.label === 'HTTPS') {
      return (
        <>
          <div className='connection-grid-field'>
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.protocolName}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <TextField
              id="configuration-Pname"
              variant="outlined"
              data-testId="configuration-Pname"
              placeholder="Enter Protocol Name"
              InputLabelProps={{
                disabled: true
              }}
              value={protocolName}
              onChange={(e) => {
                handleProtocolName('protocolName', e.target.value)
                setIsTestConnectionSuccessful(false)
              }}
              size="small"
              autoComplete="off"
              className="configuration-name-class"
              sx={{
                width: '270px',
                color: '#fffff !important'
              }}
            />
            {addState.protocolName !== '' &&
              !isValidNameEdit(addState.protocolName) && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPButtonStrings.enterValidText}
                  </span>
                </div>
            )}
            {reqError && !addState.protocolName && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.required}
                </span>
              </div>
            )}
            {addState.protocolName !== '' &&
              !isValidlength(addState.protocolName) && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPButtonStrings.enterMoreText}
                  </span>
                </div>
            )}
          </div>
        </>
      )
    } else {
      return <></>
    }
  }

  const handleURL = (): JSX.Element => {
    if (selectProtocol?.label === 'HTTPS') {
      return (
        <>
          <Grid>
            <span className='connection-setting-field-title'>
              URL<sup style={{ color: '#EE3426' }}>*</sup>
              <HtmlTooltip
                className="more-info-tooltip"
                title='Enter url with a placeholder for token as {token}, For Example: https://example.com/upload?token={token} or https://example.com/upload?auth={token}'
                placement="top"
                arrow
              >
                <IconButton>
                  <span className="more-info">
                    <img src={MoreInfo} height={20} width={20} />
                  </span>
                </IconButton>
              </HtmlTooltip>
            </span>
            <div>
              <GenericTextarea
                data-testId="url"
                placeholder="Enter URL"
                sx={{ width: 400, height: 60 }}
                value={url}
                onChange={(e) => {
                  handleUrl(e.target.value)
                  setIsTestConnectionSuccessful(false)
                }} />
              {
                reqError && selectProtocol?.label === 'HTTPS' && !url && (
                  <div className="error-response">
                    <span className="error-upload-message">
                      {CreateSFTPButtonStrings.required}
                    </span>
                  </div>
                )
              }
              {
                selectProtocol?.label === 'HTTPS' && url !== '' && !url.includes('{token}') && (
                  <div className="error-response">
                    <span className="error-upload-message">
                      URL should contain {'{token}'}
                    </span>
                  </div>
                )
              }
            </div>
          </Grid>
        </>
      )
    } else {
      return <></>
    }
  }

  const handleAuthenticationFields = (): JSX.Element => {
    if (selectProtocol?.label === 'SFTP') {
      return (
        <>
          <Grid item md={3}>
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.Authentication}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <div className="radio-box">
              <Radio
                checked={sftpAuthentication === 'ssh'}
                onChange={handleChangeAuthentication}
                value="ssh"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
                style={{ color: '#ffffff', paddingLeft: 0 }}
              />
            </div>
            <span
              style={{
                fontSize: '15px',
                padding: 10,
                display: 'block',
                fontStyle: 'italic'
              }}
            >
              {CreateSFTPStrings.SSH_Key_title}
            </span>
            <TextareaAutosize
              id="configuration-SSHkey"
              data-testId="configuration-SSHkey"
              placeholder="Enter SSH key"
              disabled={sftpAuthentication === 'password'}
              value={displayMaskValue}
              onChange={(e) => {
                handleSSHkey('SSHKey', e.target.value)
                setIsTestConnectionSuccessful(false)
              }}
              autoComplete="off"
              className="configuration-name-class"
              style={{
                backgroundColor: '#272727',
                color: '#ffffff',
                border: '1px solid #ffffff54',
                borderRadius: '4px',
                padding: '8px',
                width: '100%',
                minHeight: '40px',
                height: '40px'
              }}
            />
            {reqError && !SSHKey && sftpAuthentication !== 'password' && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.required}
                </span>
              </div>
            )}
          </Grid>
          <Grid item md={3} style={{ marginTop: '40px' }}>
            <div className="radio-box">
              <Radio
                checked={sftpAuthentication === 'password'}
                onChange={handleChangeAuthentication}
                value="password"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'B' }}
                style={{ color: '#ffffff', paddingLeft: 0 }}
              />
            </div>
            <span
              style={{
                fontSize: '15px',
                padding: 10,
                display: 'block',
                fontStyle: 'italic'
              }}
            >
              {CreateSFTPStrings.Password_title}
            </span>
            <TextField
              id="configuration-password"
              variant="outlined"
              data-testId="configuration-password"
              placeholder="Enter Password"
              type="password"
              disabled={sftpAuthentication === 'ssh'}
              InputLabelProps={{
                disabled: true
              }}
              value={password}
              onChange={(e) => {
                handlePassword('password', e.target.value)
                setIsTestConnectionSuccessful(false)
              }}
              size="small"
              autoComplete="off"
              className="configuration-name-class"
              sx={{
                width: '270px',
                color: '#fffff !important'
              }}
            />
            {reqError && !password && sftpAuthentication !== 'ssh' && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.required}
                </span>
              </div>
            )}
            {password !== '' && !isValidPasswordlength(password) && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPStrings.PasswordError}
                </span>
              </div>
            )}
          </Grid>
        </>
      )
    } else if (selectProtocol?.label === 'HTTPS') {
      return (
        <>
          <Grid item md={3}>
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.Authentication}<sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.api_token}
            </span>
            <div>
              <GenericTextarea
                placeholder='Enter API Token'
                sx={{ width: 400, height: 100 }}
                value={apiTokenMaskValue}
                onChange={(e) => {
                  handleApiToken(e.target.value)
                  setIsTestConnectionSuccessful(false)
                }} />
              {
                reqError && selectProtocol?.label === 'HTTPS' && !apiToken && (
                  <div className="error-response">
                    <span className="error-upload-message">
                      {CreateSFTPButtonStrings.required}
                    </span>
                  </div>
                )
              }
            </div>
          </Grid>
        </>
      )
    } else {
      return <></>
    }
  }

  const handlePassword = (name: string, value: string): void => {
    setPassword(value)
    const publicKeyObj = forge.pki.publicKeyFromPem(
      publicKey !== undefined ? publicKey : ''
    )
    dispatch({
      ...addState,
      password: forge.util.encode64(
        publicKeyObj.encrypt(value, 'RSA-OAEP', {
          md: forge.md.sha256.create()
        })
      )
    })
  }

  const handleSSHkey = (name: string, value: string): void => {
    setDisplayMaskValue(value.replace(/./g, '*'))
    setSSHKey(value)
    dispatch({ ...addState, sshKey: btoa(value) })
  }

  const handleApiToken = (value: string): void => {
    setApiTokenMaskValue(value.replace(/./g, '*'))
    setApiToken(value)
    dispatch({ ...addState, apiToken: btoa(value) })
  }

  const handleFolderPath = (name: string, value: string): void => {
    setFolderPath(value)
    dispatch({ ...addState, folderPath: value })
  }

  const handlePublicKey = (name: string, value: string): void => {
    setPublicKeyMaskValue(value.replace(/./g, '*'))
    setPublicHKeystate(value)
    dispatch({ ...addState, publicKey: btoa(value) })
  }

  const handleFileTypeSelection = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIsTestConnectionSuccessful(false)
    setSelectedFileType(event.target.value as 'Zipped' | 'Encrypted')
  }

  const handleOkandClose = (): void => {
    setshowSuccessPopUp(false)
    setconfirm(false)
    navigate('/configuration-protocol')
    clearAllDetails()
  }

  const handleOkandClosError = (): void => {
    setshowSuccessPopUp(false)
    setconfirm(false)
    navigate('/create-sftp-configuration')
  }

  const clearAllDetails = (): void => {
    setProtocolSpecificationName('')
    setDescription('')
    setselectProtocol(null)
    setProtocolName('')
    setIpAddress('')
    setPort('')
    setUserName('')
    setPassword('')
  }

  const isValidlength = (value: string): boolean => {
    return value.length >= 5 && value.length <= 60
  }

  const isValidUserNameLength = (value: string): boolean => {
    return value.length >= 3 && value.length <= 60
  }

  const isValidDescLength = (value: string): boolean => {
    return value.length >= 5 && value.length <= 200
  }

  const isValidPasswordlength = (value: string): boolean => {
    return value.length >= 5 && value.length <= 20
  }

  const isValidPathlength = (value: string): boolean => {
    return value.length >= 3 && value.length <= 500
  }

  const handleconfirmClick = (): void => {
    props.CreateSFTPConfigurationservice({
      connectionName: addState.connectionName,
      description: addState.description,
      protocolTypeId: addState.protocolTypeId,
      protocolName: addState.protocolName,
      ipAddress: selectProtocol?.label === 'SFTP' ? addState.ipAddress : '',
      port: selectProtocol?.label === 'SFTP' ? parseInt(addState.port) : 0,
      userName: selectProtocol?.label === 'SFTP' ? addState.userName : '',
      folderPath: selectProtocol?.label === 'SFTP' ? addState.folderPath : '',
      sshKey: selectProtocol?.label === 'SFTP' ? addState.sshKey : '',
      password: selectProtocol?.label === 'SFTP' ? addState.password : '',
      fileType: selectedFileType,
      fileEncryptFormat: selectedFileType === 'Encrypted' ? 'gpg' : '',
      publicKey: selectedFileType === 'Encrypted' ? addState.publicKey : '',
      baseUrl: selectProtocol?.label === 'HTTPS' ? addState.url : '',
      apiToken: selectProtocol?.label === 'HTTPS' ? addState.apiToken : '',
      authType: selectProtocol?.label === 'HTTPS' ? 'api_token' : ''
    })
    setconfirm(false)
    setshowSuccessPopUp(true)
  }

  const handleTestConnection = (): void => {
    props.testConnectionService({
      connectionName: addState.connectionName,
      description: addState.description,
      protocolTypeId: addState.protocolTypeId,
      protocolName: addState.protocolName,
      ipAddress: selectProtocol?.label === 'SFTP' ? addState.ipAddress : '',
      port: selectProtocol?.label === 'SFTP' ? parseInt(addState.port) : 0,
      userName: selectProtocol?.label === 'SFTP' ? addState.userName : '',
      folderPath: selectProtocol?.label === 'SFTP' ? addState.folderPath : '',
      sshKey: selectProtocol?.label === 'SFTP' ? addState.sshKey : '',
      password: selectProtocol?.label === 'SFTP' ? addState.password : '',
      fileType: selectedFileType,
      fileEncryptFormat: selectedFileType === 'Encrypted' ? 'gpg' : '',
      publicKey: selectedFileType === 'Encrypted' ? addState.publicKey : '',
      baseUrl: selectProtocol?.label === 'HTTPS' ? addState.url : '',
      apiToken: selectProtocol?.label === 'HTTPS' ? addState.apiToken : '',
      authType: selectProtocol?.label === 'HTTPS' ? 'api_token' : ''
    })
  }

  const handleChangeAuthentication = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSftpAuthentication(event.target.value)
    setIsTestConnectionSuccessful(false)
    if (event.target.value === 'ssh') {
      setPassword('')
      dispatch({ ...addState, password: '' })
    } else {
      setSSHKey('')
      setDisplayMaskValue('')
      dispatch({ ...addState, sshKey: '' })
    }
  }

  const handleCreateConfigurationWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (ProocolHttpStatus.httpStatus === 201) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={CreateSFTPStrings.CreateTitle}
          LabelPositive={'Ok'}
          onClickPositive={() => {
            handleOkandClose()
          }}
          onClose={() => {
            handleOkandClose()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {ProocolHttpStatus.httpStatus === 201
              ? CreateSFTPStrings.SucessfullyCreated
              : ProocolHttpStatus.error}
          </p>
        </Modal>
      )
    } else if (ProocolHttpStatus.isLoading) {
      return (
        <Modal title={'Loading...'}>
          <div className="CircularprogressAmi">
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (ProocolHttpStatus.httpStatus === 400) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={'Error'}
          LabelPositive={'Ok'}
          onClickPositive={() => {
            handleOkandClosError()
          }}
          onClose={() => {
            handleOkandClosError()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{ProocolHttpStatus.error}</p>
        </Modal>
      )
    } else if (ProocolHttpStatus.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (ProocolHttpStatus.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={CreateSFTPStrings.CreateTitle}
          LabelPositive={'Ok'}
          onClickPositive={() => {
            handleOkandClosError()
          }}
          onClose={() => {
            handleOkandClosError()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{ProocolHttpStatus.error}</p>
        </Modal>
      )
    }
  }

  function isConnectionNameValid (): boolean {
    if (addState.connectionName === '' || !isValidNameEdit(addState.connectionName) || !isValidlength(addState.connectionName)) {
      return false
    }
    return true
  }

  function isDescriptionValid (): boolean {
    if (addState.description !== '' && (!isValidDesc(addState.description) || !isValidDescLength(addState.description))) {
      return false
    }
    return true
  }

  const handleTestConnectionClick = (): void => {
    if (validateFields()) {
      handleTestConnection()
      setShowNotification(true)
    }
  }

  const handleSaveClick = (): void => {
    if (validateFields()) {
      setconfirm(true)
    }
  }

  const handleCloseNotification = (): void => {
    setShowNotification(false)
  }

  const handleFileTypeClick = (value: 'Zipped' | 'Encrypted'): void => {
    if (value === 'Zipped' && selectedFileType === 'Zipped') {
      setSelectedFileType('')
    } else if (value === 'Encrypted' && selectedFileType === 'Encrypted') {
      setSelectedFileType('')
    }
    setPublicHKeystate('')
    setPublicKeyMaskValue('')
    dispatch({ ...addState, publicKey: '' })
  }

  const validateFields = (): boolean => {
    const currentProtocol = selectProtocolOptions.find((val) => val.value === addState.protocolTypeId)

    if (!isConnectionNameValid()) {
      console.log('Validate', 'Connection Name', addState.connectionName)
      setReqError(true)
      return false
    }

    if (!isDescriptionValid()) {
      console.log('Validate', 'Description', addState.description)
      setReqError(true)
      return false
    }

    if (addState.protocolName === '' || !isValidNameEdit(addState.protocolName) || !isValidlength(addState.protocolName)) {
      console.log('Validate', 'Protocol Name', addState.protocolName)
      setReqError(true)
      return false
    }

    if (addState.protocolTypeId === '') {
      console.log('Validate', 'Protocol Type', addState.protocolTypeId)
      setReqError(true)
      return false
    }

    if (selectedFileType === 'Encrypted') {
      if (PublicKeystate === '') {
        console.log('Validate', 'Public Key', PublicKeystate)
        setReqError(true)
        return false
      }
    }

    if (currentProtocol?.label === 'SFTP') {
      if (addState.ipAddress === '' || !isValidIp(addState.ipAddress)) {
        console.log('Validate', 'IP Address', addState.ipAddress)
        setReqError(true)
        return false
      }
      if (addState.port === '' || !isValidPort(addState.port)) {
        console.log('Validate', 'Port', addState.port)
        setReqError(true)
        return false
      }
      if (userName === '' || !isValidName(userName) || !isValidUserNameLength(userName)) {
        console.log('Validate', 'Username', userName)
        setReqError(true)
        return false
      }
      if (addState.folderPath === '' || !isValidFolderPath(addState.folderPath)) {
        console.log('Validate', 'Folder Path', addState.folderPath)
        setReqError(true)
        return false
      }
      if (sftpAuthentication === 'password') {
        if (password === '' || !isValidPasswordlength(password)) {
          console.log('Validate', 'Password', password)
          setReqError(true)
          return false
        }
      }
      if (sftpAuthentication === 'ssh') {
        if (addState.sshKey === '') {
          console.log('Validate', 'SSH Key', addState.sshKey)
          setReqError(true)
          return false
        }
      }
    }

    if (currentProtocol?.label === 'HTTPS') {
      if (url === '' || !url.includes('{token}')) {
        console.log('Validate', 'URL', url)
        setReqError(true)
        return false
      }
      if (addState.apiToken === '') {
        console.log('Validate', 'API Token', addState.apiToken)
        setReqError(true)
        return false
      }
    }
    setReqError(false)
    return true
  }

  return (
    <>
      <div
        role="presentation"
        data-testid="breadcrumbs"
        style={{ width: '100%' }}
      >
        <Breadcrumbs
          aria-name="breadcrumb"
          sx={{
            color: '#f0f0f0',
            fontSize: '12px',
            marginBottom: '14px',
            fontFamily: 'Honeywell Sans Web',
            marginTop: '12px'
          }}
        >
          <Link
            underline="hover"
            color="inherit"
            data-testid="scheduler-breadcrumb"
            onClick={() => {
              navigate('/configuration-dashboard')
            }}
          >
            {CreateSFTPStrings.dataPublisherSettings}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}
            onClick={() => {
              navigate('/configuration-protocol')
            }}
          >
            {CreateSFTPStrings.configurationProtocol}
          </Link>
          <Typography
            color="inherit"
            sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}
          >
            {CreateSFTPStrings.addConfigurationProtocol}
          </Typography>
        </Breadcrumbs>
      </div>
      <div style={{ display: 'flex' }}>
        <ArrowBackIos
          sx={{ cursor: 'pointer' }}
          data-testid="back-arrow"
          onClick={() => {
            navigate('/configuration-protocol')
          }}
        />
        <div style={{ marginBottom: '32px' }}>
          <span
            data-testid="schedule-title"
            style={{
              color: '#f0f0f0',
              fontSize: '24px',
              fontWeight: '900',
              paddingRight: '16px',
              marginRight: '10px'
            }}
          >
            {CreateSFTPStrings.addConfigurationProtocol}
          </span>
        </div>
      </div>
      <Box sx={style} className="add-Scheduler-container">
        <Typography
          color="inherit"
          className='connection-setting-card-heading'
          sx={{
            fontSize: '16px',
            textTransform: 'uppercase',
            fontFamily: 'Honeywell Sans Web'
          }}
        >
          {CreateSFTPStrings.configurationSpecifications}
        </Typography>
        <CardContent className='connection-setting-card-content'>
          <Grid container spacing={1}>
            <Grid item md={3}>
              <span className='connection-setting-field-title'>
                {CreateSFTPStrings.name}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <TextField
                id="configuration-name"
                variant="outlined"
                data-testId="configuration-name"
                placeholder="Enter Configuration Name"
                InputLabelProps={{
                  disabled: true
                }}
                value={protocolSpecificationName}
                onChange={(e) => {
                  handleconnectionName('connectionName', e.target.value)
                  setIsTestConnectionSuccessful(false)
                }}
                size="small"
                autoComplete="off"
                className="configuration-name-class"
                sx={{
                  width: '270px',
                  color: '#fffff !important'
                }}
              />
              {addState.connectionName !== '' &&
                !isValidNameEdit(addState.connectionName) && (
                  <div className="error-response">
                    <span className="error-upload-message">
                      {CreateSFTPButtonStrings.enterValidText}
                    </span>
                  </div>
              )}
              {reqError && !addState.connectionName && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPButtonStrings.required}
                  </span>
                </div>
              )}
              {addState.connectionName !== '' &&
                !isValidlength(addState.connectionName) && (
                  <div className="error-response">
                    <span className="error-upload-message">
                      {CreateSFTPButtonStrings.enterMoreText}
                    </span>
                  </div>
              )}
            </Grid>
            <Grid item md={3}>
              <span className='connection-setting-field-title'>
                {CreateSFTPStrings.description}
              </span>
              <TextField
                id="configuration-desc"
                variant="outlined"
                data-testId="configuration-desc"
                placeholder="Enter Description"
                InputLabelProps={{
                  disabled: true
                }}
                value={description}
                onChange={(e) => {
                  handleDescription('description', e.target.value)
                  setIsTestConnectionSuccessful(false)
                }}
                size="small"
                autoComplete="off"
                className="configuration-name-class"
                sx={{
                  width: '270px',
                  color: '#fffff !important'
                }}
              />
              {addState.description !== '' &&
                !isValidDescLength(addState.description) && (
                  <div className="error-response">
                    <span className="error-upload-message">
                      {CreateSFTPButtonStrings.descriptionError}
                    </span>
                  </div>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Box>
      <Box sx={style} className="add-Scheduler-container">
        <Typography
          color="inherit"
          className='connection-setting-card-heading'
          sx={{
            fontSize: '16px',
            textTransform: 'uppercase',
            fontFamily: 'Honeywell Sans Web'
          }}
        >
          {CreateSFTPStrings.connectionSettings}
        </Typography>
        <CardContent className='connection-setting-card-content'>
          <Grid container spacing={1}>
            <div className='connection-grid-field'>
              <span className='connection-setting-field-title'>
                {CreateSFTPStrings.protocol}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <GenXDropdown
                placeholder="Select Protocol"
                data-testid='protocol'
                value={selectProtocol}
                options={selectProtocolOptions}
                onSelect={handleProtocol}
                width={270}
              />
              {reqError && !addState.protocolTypeId && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPButtonStrings.required}
                  </span>
                </div>
              )}
            </div>
            { handleProtocolFields() }
          </Grid>
          <Grid> {handleURL()} </Grid>
        </CardContent>
        <CardContent className='connection-setting-card-content'>
          <Grid container spacing={1}>
            { handleAuthenticationFields() }
          </Grid>
        </CardContent>

        <CardContent className='connection-setting-card-content'>
          <Grid container spacing={1}>
            <div>
              <div style={{ fontSize: '15px', padding: 10 }}>
                {CreateSFTPStrings.file_type} <span style={{ color: '#B0B0B0' }}>(Optional)</span>
              </div>
              <div className="radio-box" style={{ marginLeft: 10 }}>
                <Radio
                  onClick={() => { handleFileTypeClick('Zipped') }}
                  checked={selectedFileType === 'Zipped'}
                  onChange={handleFileTypeSelection}
                  data-testid='radiobtn_decrypt'
                  value="Zipped"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'Zipped' }}
                  style={{ color: '#ffffff', paddingLeft: 0 }}
                  unselectable='on'
                />
              </div>
              <span
                style={{
                  fontSize: '15px',
                  padding: 10,
                  display: 'block',
                  fontStyle: 'italic'
                }}
              >
                { CreateSFTPStrings.zipped }
              </span>
            </div>
            <div style={{ marginTop: '40px' }}>
              <div className="radio-box">
                <Radio
                  data-testid='radiobtn_encrypt'
                  checked={selectedFileType === 'Encrypted'}
                  onClick={() => { handleFileTypeClick('Encrypted') }}
                  onChange={handleFileTypeSelection}
                  value="Encrypted"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'Encrypted' }}
                  style={{ color: '#ffffff', paddingLeft: 0 }}
                  unselectable='on'
                />
              </div>
              <span className='connection-setting-field-title'>
                 { CreateSFTPStrings.encrypted }
                <HtmlTooltip
                  className="more-info-tooltip"
                  title='Enter Public Key'
                  placement="top"
                  arrow
                >
                  <IconButton>
                    <span className="more-info">
                      <img src={MoreInfo} height={20} width={20} />
                    </span>
                  </IconButton>
                </HtmlTooltip>
              </span>
              <div style={{ paddingLeft: 40 }}>
                <TextareaAutosize
                  style={{
                    backgroundColor: '#272727',
                    color: '#ffffff',
                    border: '1px solid #ffffff54',
                    borderRadius: '4px',
                    padding: '8px',
                    width: '100%',
                    minHeight: '90px',
                    height: '90px'
                  }}
                  value={publicKeyMaskValue}
                  disabled={selectedFileType !== 'Encrypted'}
                  placeholder="Enter Public Key"
                  onChange={(e) => {
                    handlePublicKey('PublicKey', e.target.value)
                    setIsTestConnectionSuccessful(false)
                  }}
                />
                <div><span>gpg only</span></div>
                {reqError && !addState.publicKey && selectedFileType === 'Encrypted' && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPButtonStrings.required}
                  </span>
                </div>
                )}
              </div>
            </div>
            <div>
            </div>
          </Grid>
        </CardContent>
      </Box>
      <Box sx={style} className="add-configuration-container">
        <Grid container spacing={1}>
          <Grid style={{ textAlign: 'left' }} item md={6}>
            <Button
              type="borderless"
              data-testid="cancel-btn"
              onClick={() => {
                navigate('/configuration-protocol')
                clearAllDetails()
              }}
            >
              {CreateSFTPButtonStrings.CancelBtn}
            </Button>
          </Grid>
          <Grid style={{ textAlign: 'right' }} item md={6}>
            <Button
              type='hollow'
              width='164px'
              dataTestId='testconn-btn'
              onClick={() => {
                handleTestConnectionClick()
              }}
            >
              {CreateSFTPStrings.test_connection}
            </Button>
            <Button
              dataTestId='savebtn'
              primaryColor= {(isTestConnectionSuccessful && !reqError) ? undefined : '#B0B0B0'}
              disabled= {!(isTestConnectionSuccessful && !reqError)}
              onClick={() => {
                handleSaveClick()
              }}
            >
              {CreateSFTPStrings.save_btn}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {confirm && (
        <Modal
          titleStyle={{ paddingRight: '2em', marginBottom: '20px' }}
          buttonTypeNegative="hollow"
          LabelPositive={CreateSFTPButtonStrings.ConfirmBtn}
          LabelNegative={CreateSFTPButtonStrings.CancelBtn}
          onClickPositive={() => {
            handleconfirmClick()
          }}
          onClose={() => {
            setconfirm(false)
          }}
          onClickNegative={() => {
            setconfirm(false)
          }}
          title={'Add Connection Setting'}
        >
          <div className="confirm-msg" style={{ marginBottom: '30px' }}>
            <span>{CreateSFTPStrings.CreateConfirm}</span>
          </div>
        </Modal>
      )}
      {showSuccessPopUp && handleCreateConfigurationWithFailure()}
      {showNotification && (
        <Notification containerStyle={{ position: 'fixed', bottom: '170px', right: '32px' }} httpStatus={props.TestConnectionData.httpStatus ?? 0} message={props.TestConnectionData.message ?? ''} loading={props.TestConnectionData.isLoading} onClose={handleCloseNotification} />
      )}
    </>
  )
}

interface DispatchToProps {
  CreateSFTPConfigurationservice: (createReportSchedulerTemplatePostRequest: SFTPConfigurationData) => void
  testConnectionService: (testConnectionPostRequest: SFTPConfigurationData) => void
  getConfigurationDropdownMetadata: () => void
  getPublicKeyData: () => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  CreateSFTPConfigurationservice: (createReportSchedulerTemplatePostRequest: SFTPConfigurationData) => dispatch(CreateSFTPConfigurationservice(createReportSchedulerTemplatePostRequest)),
  testConnectionService: (testConnectionPostRequest: SFTPConfigurationData) => dispatch(TestConnectionService(testConnectionPostRequest)),
  getConfigurationDropdownMetadata: () => dispatch(getConfigurationDropdownMetadata()),
  getPublicKeyData: () => dispatch(getPublicKeyData())
})

interface StateToProps {
  SFTPConfigurationData: CreateSFTPConfigurationState
  TestConnectionData: testConnectionState
  ConfigurationMetadata: ConfigurationMetadataDropDownState
  PublicKeyData: PublicKeyDataState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  SFTPConfigurationData: state.SFTPConfigurationData,
  TestConnectionData: state.testConnectionData,
  ConfigurationMetadata: state.ConfigurationMetadata,
  PublicKeyData: state.PublicKeyData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(CreateSftpConfiguration)
