/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as AssetByIdService from '../services/assetByIdService'
import * as AlarmByIdService from '../services/alarmsByIdService'
import { assetByIdDataLoading, assetByIdDataError, addAssetByIdData, type Asset } from './assetByIdSlice'
import { getAllTagsByIdDataLoading, getAllTagsByIdDataError, getAllTagsByIdData } from './getAllTagsByIdSlice'
import { addChannelData, channelDataLoading, channelDataError } from './channelDataSlice'
import { addRegTableData, RegTableDataLoading, RegTableDataError } from './registerReadingsSlice'
import * as IntervalReadingsService from '../services/intervalReadingsService'
import * as KpiIntervalReadingsService from '../services/kpiIntervalReadingsService'
import * as RegReadingsService from '../services/regReadTableService'
import { getNetworkKpiOptions, getConsumptionDataOptions } from '../components/dataSourceComponents/ChartOptions'
import * as EventService from '../services/eventService'
import { deviceListErrorAction, deviceListLoadingAction, deviceListUpdateAction } from './deviceListSlice'
import * as DeviceListService from '../services/deviceListService'
import { alarmByIdDataLoading, alarmByIdDataError, addAlarmByIdData, type Alarm } from './alarmsByIdSlice'
import { signalDataLoadingAction, signalDataUpdateAction, signalDataErrorAction, rawDataUpdateAction } from './cellularSignalSlice'
import * as cellularSignalService from '../services/networkGraphService'
import * as ChannelDropdownService from '../services/channelDropDownService'
import { channelDropdownLoading, addChannelDropdownData, channelDropdownError } from './channelDropdownSlice'
import { EditAssetSummaryInfoDataLoading, EditAssetSummaryInfoDataError, EditAssetSummaryInfoData, ResetEditAssetSummaryParamsData } from './editAssetSummaryInfoSlice'
import * as EditAssetSummaryService from '../services/editAssetSummaryService'
import { type editSummaryReq } from '../services/editAssetSummaryService'
import * as getAllTagsByIdService from '../services/getAllTagsById'
import { deviceHistoryErrorAction, deviceHistoryLoadingAction, deviceHistoryUpdateAction } from './deviceLifeCycleHistorySlice'
import * as DeviceHistoryService from '../services/deviceLifeCycleHistoryService'
import { getAlarmHistory } from '../services/alarmHistoryService'
import { type KpiData } from '../services/networkGraphService'
import { alarmHistoryLoadingAction, alarmHistoryUpdateAction, alarmHistoryErrorAction } from './alarmHistorySlice'
import type DeviceUtil from '../../../utils/DeviceUtil'
import { getGraphColorByName, getGraphParameters, ID_TO_NETWORK_KPI } from '../../../utils/NetworkGraphUtils'
import { addEventsTableData, EventsTableDataError, EventsTableDataLoading } from './eventsSlice'
import { type ChannelDropdownData } from '../services/channelDropDownService'
import { getAssetParameter } from '../services/assetParameterService'
import { assetAttrParameterLoadingAction, assetAttrParameterUpdateAction, assetAttrParameterErrorAction } from './assetAttrParameterSlice'
import { getAssetAttribute } from '../services/assetAttributeService'
import { assetAttributeLoadingAction, assetAttributeUpdateAction, assetAttributeErrorAction } from './assetAttributeSlice'
import * as TopologyService from '../services/topologyByIdService'
import { addTopologyData, topologyError, topologyLoading } from './topologyDataSlice'
import { meterImageErrorAction, meterImageLoadingAction, meterImageUpdateAction } from './getMeterImageSlice'
import { getMeterImage } from '../services/getMeterImageService'
import { exportAssetConfigErrorAction, exportAssetConfigLoadingAction, exportAssetConfigUpdateAction } from './exportAssetConfigDetailsSlice'
import { exportAssetConfigDetails } from '../services/exportAssetConfigDetailsService'

export const editAssetSummaryInfo = (editSummaryParamter: editSummaryReq, assetId: string, tenantId: string) => async (dispatch: any) => {
  dispatch(getAllTagsByIdDataLoading())
  void EditAssetSummaryService.editAssetSummary(editSummaryParamter, assetId, tenantId, (message: string, httpStatus: number) => {
    dispatch(EditAssetSummaryInfoData({ message, httpStatus }))
  },
  // istanbul ignore next
  (message: string, httpStatus: number) => {
    dispatch(EditAssetSummaryInfoDataError({ message, httpStatus }))
  })
}

export const getAllTagsByIDInfo = (search: string) => async (dispatch: any) => {
  dispatch(EditAssetSummaryInfoDataLoading())
  void getAllTagsByIdService.getAllTagsById(search, (data: any, httpStatus: number) => {
    dispatch(getAllTagsByIdData({ data, httpStatus }))
  },
  (errorMessage: string, httpStatus: number) => {
    dispatch(getAllTagsByIdDataError({ errorMessage, httpStatus }))
  })
}

export const resetEditAssetSummaryParamsData = () => async (dispatch: any) => {
  dispatch(ResetEditAssetSummaryParamsData())
}

const parseAssetData = (assetData: Asset): Asset => {
  const remBatPercent = assetData.rem_bat_percent !== null ? parseFloat(assetData.rem_bat_percent.toFixed(2)) : assetData.rem_bat_percent

  return {
    id: assetData.id,
    name: assetData.name,
    serial_number: assetData.serial_number,
    device_type: assetData.device_type,
    comm_type: assetData.comm_type,
    rem_bat_percent: remBatPercent,
    comm_mod_serial: assetData.comm_mod_serial,
    asset_state: assetData.asset_state,
    latitude: assetData.latitude,
    longitude: assetData.longitude,
    install_date: assetData.install_date,
    firmware_version: assetData.firmware_version,
    removal_date: assetData.removal_date,
    ip_address: assetData.ip_address,
    port: assetData.port,
    tag: assetData.tag,
    installation_remarks: assetData.installation_remarks
  }
}

const parseAlarmData = (alarmData: AlarmByIdService.AlarmData): Alarm[] => {
  const result = alarmData.alarmDetails.map((alarm) => {
    return {
      alarmDefinitionId: alarm.alarmDefinitionId,
      alarmName: alarm.alarmName,
      severity: alarm.severity,
      alarmStatus: alarm.alarmStatus,
      readingTime: alarm.readingTime
    }
  })
  return result
}

const parseTopologyData = (TopologyData: any): any => {
  return TopologyData
}

export const fetchAssetData = (
  id: string,
  tenantId: string,
  loading = true
) => (dispatch: any) => {
  if (loading) {
    dispatch(assetByIdDataLoading())
  }
  void AssetByIdService.getAssetById(
    id,
    tenantId,
    (assetData, httpStatus) => {
      const parsedData = parseAssetData(assetData)
      dispatch(addAssetByIdData({ data: parsedData, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(assetByIdDataError({ errorMessage, httpStatus }))
    }
  )
}

const parseChartSeriesData = (data: IntervalReadingsService.IntervalReading): number[][] => {
  const sortedData = data.assetReadingsData.sort((item1, item2) => (new Date(item1.readingTime).getTime() - new Date(item2.readingTime).getTime()))
  const res = sortedData.map((arrElem: any) => [(new Date(arrElem.readingTime)).getTime(), arrElem.value, arrElem.indexValue])
  return res
}

export const fetchChannelData = (assetId: string, fromDate: string, toDate: string, selectedChannel: string, selectedChannelUnit: string, tenantId: string, toolTip: ChannelDropdownData | undefined) => (dispatch: any) => {
  dispatch(channelDataLoading())
  void IntervalReadingsService.getIntervalReadings(
    assetId,
    fromDate,
    toDate,
    selectedChannel,
    tenantId,
    (intervalReadings, httpStatus) => {
      const unit = selectedChannelUnit !== undefined ? selectedChannelUnit : ''
      const consumptionOptions = getConsumptionDataOptions(
        parseChartSeriesData(intervalReadings), unit, selectedChannel, toolTip
      )
      const channelDataOptions = {
        consumptionChartOptions: consumptionOptions
      }

      dispatch(addChannelData({ data: channelDataOptions, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(channelDataError({ errorMessage, httpStatus }))
    }
  )
}

export const fetchWaterKpiData = (assetId: string, fromDate: string, toDate: string, selectedChannelUnit: string, selectedKpi: string, tenantId: string, channelAttributes: ChannelDropdownData | undefined) => (dispatch: any) => {
  dispatch(channelDataLoading())
  void KpiIntervalReadingsService.getKpiIntervalReadings(
    assetId,
    selectedKpi,
    fromDate,
    toDate,
    tenantId,
    (intervalReadings, httpStatus) => {
      const consumptionOptions = getConsumptionDataOptions(
        parseChartSeriesData(intervalReadings), selectedChannelUnit, selectedKpi, channelAttributes
      )
      const channelDataOptions = {
        consumptionChartOptions: consumptionOptions
      }

      dispatch(addChannelData({ data: channelDataOptions, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(channelDataError({ errorMessage, httpStatus }))
    }
  )
}

export const fetchChannelDropdownData = (assetId: string, tenantId: string) => (dispatch: any) => {
  dispatch(channelDropdownLoading())

  void ChannelDropdownService.getChannelDropdownData(
    assetId,
    tenantId,
    (data: ChannelDropdownData[], httpStatus: number) => {
      dispatch(addChannelDropdownData({ data, httpStatus }))
    },
    (errorMessage: string, httpStatus: number) => {
      dispatch(channelDropdownError({ errorMessage, httpStatus }))
    }
  )
}

// istanbul ignore next
export const fetchTopology = (assetId: string, tenantId: string) => (dispatch: any) => {
  dispatch(topologyLoading())

  void TopologyService.getTopologyById(
    assetId,
    tenantId,
    (data) => {
      const parsedData = parseTopologyData(data)
      dispatch(addTopologyData(parsedData))
    },
    (errorMessage) => {
      dispatch(topologyError(errorMessage))
    }
  )
}

export const fetchDeviceList = (page: any, size: any, sortdir: any, sortfield: any) => async (dispatch: any): Promise<void> => {
  dispatch(deviceListLoadingAction())

  void DeviceListService.getDeviceList(
    page,
    size,
    sortdir,
    sortfield,
    (deviceList) => {
      dispatch(deviceListUpdateAction({ data: deviceList.assets, count: deviceList.total_count }))
    },
    (errorMessage) => {
      dispatch(deviceListErrorAction(errorMessage))
    }
  )
}

const parseRegTableData = (data: any): any => {
  const res = data.assetReadingsData.map((arrElem: any, index: number) => {
    return {
      id: index + 1,
      register: arrElem.registerName,
      measurementPeriod: arrElem.readingTime,
      eventType: '--',
      collected: arrElem.value,
      deltaValue: '--',
      lastUpdated: null,
      calculatedValue: arrElem.calculatedValue
    }
  })
  return res
}

const parseEventsTableData = (data: any): any => {
  console.log('parseEventsTableData', data)
  const res = data.events.map((arrElem: any, index: number) => {
    return {
      id: index + 1,
      event: arrElem.eventType.translation[0].translation,
      eventTime: arrElem.recordedAt,
      collectedTime: arrElem.collectionTime
    }
  })
  return res
}

export const fetchEventsTableData = (assetId: string, page: number, size: number, tenantId: string, sortdir: string, sortfield: string) => (dispatch: any) => {
  dispatch(EventsTableDataLoading())

  void EventService.getEvents(
    assetId,
    page,
    size,
    tenantId,
    sortdir,
    sortfield,
    (events, httpStatus) => {
      const ParsedRegTableData = parseEventsTableData(events)
      dispatch(addEventsTableData({ data: ParsedRegTableData, count: events.total_count, httpStatus } as any))
    },
    (errorMessage, httpStatus) => {
      dispatch(EventsTableDataError({ errorMessage, httpStatus } as any))
    }
  )
}

export const fetchRegReadTableData = (assetId: string, page: string, size: string, tenantId: string, sortdir: string, sortfield: string) => (dispatch: any) => {
  dispatch(RegTableDataLoading())

  void RegReadingsService.getIntervalReadings(
    assetId,
    page,
    size,
    tenantId,
    sortdir,
    sortfield,
    (intervalReadings, httpStatus) => {
      const ParsedRegTableData = parseRegTableData(intervalReadings)
      dispatch(addRegTableData({ data: ParsedRegTableData, count: intervalReadings.total_count, httpStatus } as any))
    },
    (errorMessage, httpStatus) => {
      dispatch(RegTableDataError({ errorMessage, httpStatus } as any))
    }
  )
}

export const fetchAlarmsById = (
  id: string,
  tenantId: string
) => (dispatch: any) => {
  dispatch(alarmByIdDataLoading())

  void AlarmByIdService.getAlarmsById(
    id,
    tenantId,
    // istanbul ignore next
    (alarmData: AlarmByIdService.AlarmData, httpStatus) => {
      const parsedData = parseAlarmData(alarmData)
      dispatch(addAlarmByIdData({ AlarmData: parsedData, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(alarmByIdDataError({ errorMessage, httpStatus }))
    }
  )
}

function formatDate (inputDate: any) {
  const date = new Date(inputDate)
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day = String(date.getUTCDate()).padStart(2, '0')
  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')
  const seconds = String(date.getUTCSeconds()).padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000+00:00`
  return formattedDate
}

// istanbul ignore next
function parseData (jsonData: KpiData[]): Array<[number, number | null]> {
  const timestamps = []
  const kpiValues: Array<number | null> = []

  for (const entry of jsonData) {
    timestamps.push(entry.timestamp)
    kpiValues.push(entry.kpi_value === 4.9E-324 ? null : entry.kpi_value)
  }
  const formattedData = createArray(kpiValues, timestamps)
  return formattedData
}

function createArray (values: Array<number | null>, timestamps: string[]) {
  const data: Array<[number, number | null]> = []
  for (let i = 0; i < values.length; i++) {
    data.push([new Date(formatDate(timestamps[i])).getTime(), values[i]])
  }

  const formattedData = data.sort((prev, next) => new Date(prev[0]).getTime() - new Date(next[0]).getTime())
  return (formattedData)
}

export const fetchSignalData =
  (tenant: string, assetId: string, from: string, to: string, assetType: keyof typeof DeviceUtil.deviceTypes) => async (dispatch: any) => {
    dispatch(signalDataLoadingAction())
    void cellularSignalService.getNetworkGraphData(
      tenant,
      assetId,
      from,
      to,
      assetType,
      (data: KpiData[], httpStatus) => {
        const graphParameters = getGraphParameters(assetType)
        const seriesData = graphParameters.kpiDefinitionIds.map((kpiDefinitionId) => {
          return {
            name: ID_TO_NETWORK_KPI[kpiDefinitionId],
            color: getGraphColorByName(ID_TO_NETWORK_KPI[kpiDefinitionId]),
            data: parseData(data.filter((entry) => entry.kpi_definition_id === kpiDefinitionId))
          }
        })
        const chartArrayData = getNetworkKpiOptions(seriesData)
        const channelDataOptions = {
          consumptionChartOptions: chartArrayData
        }
        dispatch(signalDataUpdateAction({ data: channelDataOptions, httpStatus }))
        if (seriesData.every((entry) => entry.data.length === 0)) {
          dispatch(rawDataUpdateAction({ raw: true, httpStatus }))
        }
      },
      (errorMessage, httpStatus) => {
        dispatch(signalDataErrorAction({ errorMessage, httpStatus }))
      }
    )
  }
// istanbul ignore next
export const fetchDeviceHistory = (assetId: string, tenantId: string) => async (dispatch: any): Promise<void> => {
  dispatch(deviceHistoryLoadingAction())

  void DeviceHistoryService.getDeviceHistory(
    assetId,
    tenantId,
    (deviceHistory) => {
      dispatch(deviceHistoryUpdateAction({ data: deviceHistory.state_details, count: deviceHistory.total_count }))
    },
    (errorMessage) => {
      dispatch(deviceHistoryErrorAction(errorMessage))
    }
  )
}

export const fetchAlarmHistory = (assetId: string, page: number, size: number) => async (dispatch: any): Promise<void> => {
  dispatch(alarmHistoryLoadingAction())
  void getAlarmHistory(
    assetId,
    page,
    size,
    (alarmHistoryData, httpStatus) => {
      dispatch(alarmHistoryUpdateAction({ data: alarmHistoryData, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(alarmHistoryErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchAssetAttribute = (assetId: string) => async (dispatch: any): Promise<void> => {
  dispatch(assetAttributeLoadingAction())
  void getAssetAttribute(
    assetId,
    (assetAttribute, httpStatus) => {
      dispatch(assetAttributeUpdateAction({ data: assetAttribute, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(assetAttributeErrorAction({ errorMessage, httpStatus }))
    }
  )
}

// istanbul ignore next
export const fetchAssetAttrParameter = (assetId: string, configurationId: string, page: number, size: number) => async (dispatch: any): Promise<void> => {
  dispatch(assetAttrParameterLoadingAction())
  void getAssetParameter(
    assetId,
    configurationId,
    page,
    size,
    (assetAttrParameter, httpStatus) => {
      dispatch(assetAttrParameterUpdateAction({ data: assetAttrParameter, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(assetAttrParameterErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchMeterImage = (assetId: string) => async (dispatch: any): Promise<void> => {
  dispatch(meterImageLoadingAction())
  void getMeterImage(
    assetId,
    (meterImage, httpStatus) => {
      dispatch(meterImageUpdateAction({ data: meterImage.data, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(meterImageErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const exportAssetConfigDetail = (assetId: string, size: number) => async (dispatch: any) => {
  dispatch(exportAssetConfigLoadingAction())
  try {
    await exportAssetConfigDetails(assetId, size, (message: any) => {
      dispatch(exportAssetConfigUpdateAction(message))
    },
    (errorMessage: any) => {
      dispatch(exportAssetConfigErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(exportAssetConfigErrorAction(e))
  }
}
