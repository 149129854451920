import { exportFileFormat } from '../../../utils/ExportFileUtils'
import interceptedAxios from '../../../utils/interceptedAxios'

export async function exportAssetConfigDetails (
  assetId: string,
  size: number,
  onSuccess: (exportFile: Blob) => void,
  onFailure: (errorMessage: string) => void
): Promise<void> {
  const url = `/assetrest/api/v1/udp/assets/asset-configuration/download?assetId=${assetId}&size=${size}`

  interceptedAxios.get(url, { responseType: 'blob', timeout: 10000 })
    .then(response => {
      exportFileFormat(response, 'AssetConfigList_')
      onSuccess(response.data)
    })
    .catch(error => {
      if (error instanceof Error) {
        onFailure(error.message)
      } else {
        onFailure('An unknown error occurred')
      }
    })
}
