/* eslint-disable */
import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'
import { exportFileFormat } from '../../../utils/ExportFileUtils'

const ZParams = z.object({
    groupId: z.string(),
    groupName: z.string(),
    status: z.string()
})

const ZFirmwareListingDetails = z.object({
    fileId: z.string().nullable(),
    firmwareType: z.string().nullable(),
    assetType: z.string().nullable(),
    version: z.string().nullable(),
    minimumMeterVersion: z.string().optional(),
    minimumCommunicationVersion: z.string().optional(),
    imageIdentifier: z.string().optional(),
    description: z.string().optional(),
    groupsDto: z.array(ZParams)
})

const ZData = z.object({
    total_count: z.number(),
    firmware: ZFirmwareListingDetails.array()
})

const ZApiResponse = z.object({
    http_status: z.number(),
    error: z.boolean(),
    data: ZData
})
const ZApiFilterResponse = z.object({
    http_status: z.number(),
    error: z.boolean(),
    data: z.optional(z.array(z.string()))
})


export type FirmwareListingDetails = z.infer<typeof ZFirmwareListingDetails>
export type GroupParaObj = z.infer<typeof ZParams>
export type DataObj = z.infer<typeof ZData>

export async function getFirmwareListingDetails(
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    search: string,
    tenantId: string,
    onSuccess: (firmwareListData: DataObj, httpStatus: number) => any,
    onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
    const url = `/fileuploadservice/api/v1/udp/ami/devices/firmware?page=${page}&size=${size}&sort=${sortfield},${sortdir}&search=${search}&tenantId=${tenantId}`
    interceptedAxios.get(url, { timeout: 10000 })
        .then((response) => {
            // istanbul ignore else
            if (response.status === 200) {
                const parseResponse = ZApiResponse.safeParse(response.data)
                if (parseResponse.success) {
                    // istanbul ignore else
                    if (response.data.data !== null && response.data.data !== undefined) {
                        onSuccess(parseResponse.data.data, response.status)
                    }
                } else {
                    // istanbul ignore next
                    onFailure(parseResponse.error.message, response.status)
                }
            }
        })
        // istanbul ignore next
        .catch((error) => {
            // istanbul ignore next
            onFailure(error.message, error.response.status)
        })
}

export async function getFirmwareListingFilteredData(
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    filterParams: any,
    tenantId: string,
    onSuccess: (firmwareListData: any, httpStatus: number) => any,
    onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
    const url = `/fileuploadservice/api/v1/udp/ami/devices/firmware/filter?page=${page}&size=${size}&sort=${sortfield},${sortdir}&tenantId=${tenantId}`
    interceptedAxios.post(url, filterParams)
        .then((response) => {
            if (response.status === 200) {
                const parseResponse = ZApiResponse.safeParse(response.data)
                if (parseResponse.success) {
                    if (response.data.data !== null && response.data.data !== undefined) {
                        onSuccess(parseResponse.data.data, response.status)
                    }
                } else {
                    onFailure(parseResponse.error.message, response.status)
                }
            }
        })
        .catch((error) => {
            onFailure(error.message, error.response.status)
        })
}

export async function getFirmwareListingFilterAssetType(
    tenantId: string,
    onSuccess: (firmwareListFilterAssetType: any) => any,
    onFailure: (errorMessage: string) => any
): Promise<void> {
    const url = `/fileuploadservice/api/v1/udp/ami/devices/firmware/assetType?tenantId=${tenantId}`
    interceptedAxios.get(url, { timeout: 10000 })
        .then((response) => {
             // istanbul ignore else
            if (response.status === 200) {
                const parseResponse = ZApiFilterResponse.safeParse(response.data)
                 // istanbul ignore else
                if (parseResponse.success) {
                     // istanbul ignore else
                    if (response.data.data !== null && response.data.data !== undefined) {
                        onSuccess(parseResponse.data.data)
                    }
                } else {
                    onFailure(parseResponse.error.message)
                }
            }
        })
        .catch((error) => {
            onFailure(error.message)
        })
}

export async function getFirmwareListingFilterFirmwareType(
    tenantId: string,
    onSuccess: (firmwareListFilterFirmwareType: any) => any,
    onFailure: (errorMessage: string) => any
): Promise<void> {
    const url = `/fileuploadservice/api/v1/udp/ami/devices/firmware/firmwareType?tenantId=${tenantId}`
    interceptedAxios.get(url, { timeout: 10000 })
        .then((response) => {
            if (response.status === 200) {
                const parseResponse = ZApiFilterResponse.safeParse(response.data)
                if (parseResponse.success) {
                    if (response.data.data !== null && response.data.data !== undefined) {
                        onSuccess(parseResponse.data.data)
                    }
                } else {
                    onFailure(parseResponse.error.message)
                }
            }
        })
        // istanbul ignore next
        .catch((error) => {
            onFailure(error.message)
        })
}
export async function getFirmwareListingFilterVersion(
    tenantId: string,
    onSuccess: (firmwareListFilterVersion: any) => any,
    onFailure: (errorMessage: string) => any
): Promise<void> {
    const url = `/fileuploadservice/api/v1/udp/ami/devices/firmware/version?tenantId=${tenantId}`
    interceptedAxios.get(url, { timeout: 10000 })
        .then((response) => {
            // istanbul ignore else
            if (response.status === 200) {
                const parseResponse = ZApiFilterResponse.safeParse(response.data)
                // istanbul ignore else
                if (parseResponse.success) {
                    // istanbul ignore else
                    if (response.data.data !== null && response.data.data !== undefined) {
                        onSuccess(parseResponse.data.data)
                    }
                } else {
                    onFailure(parseResponse.error.message)
                }
            }
        })
        // istanbul ignore next
        .catch((error) => {
            onFailure(error.message)
        })
}

// istanbul ignore next
export async function exportFirmwareList(
    tenantId: string,
    onSuccess: (exportFile: any) => any,
    onFailure: (errorMessage: string) => any
): Promise<void> {
    const url = `/fileuploadservice/api/v1/udp/ami/devices/firmware/download?tenantId=${tenantId}`
    interceptedAxios
        .get(url, { responseType: 'blob', timeout: 10000 })
        .then((response: any) => {
            exportFileFormat(response, 'FirmwareList_')
            onSuccess(response)
        })
        .catch((error) => {
            onFailure(error.message)
        })
}


export async function deleteFirmwareById(
    firmwareId: string,
    tenantId: string,
    onSuccess: (deleteMessage: any) => any,
    onFailure: (errorMessage: any) => any
): Promise<void> {
    const url = `/fileuploadservice/api/v1/udp/ami/devices/firmware/delete/${firmwareId}?tenantId=${tenantId}`
    interceptedAxios.delete(url)
        .then((response) => {
            // istanbul ignore else
            if (response.status === 200) {
                onSuccess('Success')
            }
        })
        .catch((error) => {
            onFailure(error.message)
        })
}