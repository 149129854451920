import { Button, Card, Footer, Input } from '@scuf/common'
import './Login.css'
import '../../index.css'
import React, { type FormEvent, useState } from 'react'
import forgeLogo from '../../assets/Forge-Logo.svg'
import { useAuth } from 'react-oidc-context'
import LanguageStrings from '../../i18n/locales'

const dashboardStrings = LanguageStrings().Dashboard

const Login = (): any => {
  const authContext = useAuth()
  const [username, setUsername] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)
  const [enabled, setEnabled] = useState(false)

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    authContext
      .signinRedirect({
        login_hint: username
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const onUsernameChange = (value: string): void => {
    const regex = /^(?=.{1,200}$)[^@]+@[^@]+\.[^@]+$/
    if (value === '') {
      setError(undefined)
      setEnabled(false)
    } else if (regex.test(value)) {
      setError(undefined)
      setEnabled(true)
    } else if (value.length > 200) {
      setError('Maximum email length should not be more than 200')
    } else {
      setError('Enter a valid email address')
      setEnabled(false)
    }
    setUsername(value)
  }

  return (
    <React.StrictMode>
      <div className="login-layout">
        <Card className="rounded login-card">
          <Card.Content>
            <form onSubmit={onSubmit} className="login-form">
              <img
                className="login-form-logo"
                src={forgeLogo}
                alt="Honeywell Forge Logo"
              />
              <h4>Sign In</h4>
              <Input
                className="rounded"
                value={username}
                error={error}
                onChange={onUsernameChange}
                placeholder="Username"
                label="Username"
                reserveSpace={true}
                fluid={true}
                arial-label="username-label"
              />
              <Button
                className="rounded"
                type="primary"
                content="Sign in"
                actionType="submit"
                disabled={!enabled}
                textTransform={false}
              />
              <div className="honeywell-privacy-statement">
                <p>Your personal data will be processed in</p>
                <p>accordance with</p>
                <a target='_blank' rel="noreferrer noopener" href="https://www.honeywell.com/us/en/privacy-statement">
                  Honeywell Privacy Statement
                </a>
              </div>
            </form>
          </Card.Content>
        </Card>
      </div>
      <Footer style={{ width: '100%', position: 'fixed', bottom: 0 }}>
        <Footer.Item>
          {dashboardStrings.Version} -{' '}
          {window._env_?.REACT_APP_SERVICE_VERSION !== undefined
            ? window._env_.REACT_APP_SERVICE_VERSION
            : ''}
        </Footer.Item>
        <Footer.Item
          href="https://www.honeywell.com/us/en/privacy-statement"
          target="_blank"
        >
          {dashboardStrings.Privacy_Policy}
        </Footer.Item>
        <Footer.Item
          href="https://Honpat.com"
          target="_blank"
        >
          {dashboardStrings.Patents}
        </Footer.Item>
      </Footer>
    </React.StrictMode>
  )
}

export default Login
