import { formatDownloadName } from '../../../utils/ExportFileUtils'
import interceptedAxios from '../../../utils/interceptedAxios'

export async function exportSchedulerGrid (
  tenantId: string,
  onSuccess: (exportFile: any) => any,
  onFailure: (errorMessage: string) => any
): Promise<void> {
  const url = `/report-service/api/v1/udp/scheduler/download?tenantId=${tenantId}`
  interceptedAxios
    .get(url, { responseType: 'blob', timeout: 10000 })
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'text/csv' })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', formatDownloadName('Scheduler_Data_'))
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
      onSuccess(response)
    })
    .catch((error) => {
      onFailure(error)
    })
}
